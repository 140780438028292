import React, { Fragment } from 'react';
export const keys = (v = {}) => ({

  // Customized strings for different markets - these will override the default STRINGS
  MARKET_STRINGS: {
    // Sweden
    SE: {
    },

    // Norway
    NO: {
    },

    // Finland
    FI: {
    }
  },

  // Default strings
  STRINGS: {
    'signed_with_bankid': `Signed with BankID ${v.date}`,

    // UI Strings
    'abort': 'Cancel',
    'accept_and_proceed': 'Approve and continue',
    'accept_terms_confirm': 'I am aware and confirm:',
    'accepted': 'Accepted',
    'accomplished': 'Accomplished',
    'account_balance': 'Account balance',
    'account_number': 'Account number',
    'account': 'Account',
    'accrued_interest': 'Accrued interest',
    'acquisition_cost': 'Acquisition value',
    'actions': 'Actions', // used in CRM for som tools to edit
    'active_investments': 'Trade', // Aktiva investeringar
    'add_row': 'Add row',
    'add': 'Add',
    'advice': 'Advice',
    'aftersale': 'Efterköpsinformation', // translate
    'all_accounts': 'All accounts',
    'all': 'All',
    'amortization': 'Amortization',
    'amount_exkl_court': `Amount (${v.unit}, excluding brokerage fee)`,
    'amount': 'Amount',
    'and': 'and',
    'annual_interest_rate': 'Annual interest rate',
    'answer': 'Answer',
    'aptitude_test': 'Appropriateness test',
    'arranger': 'Arranger',
    'at_maturity': 'Maturity',
    'attach_file_desc': <Fragment>or <strong>drag and drop</strong></Fragment>, // As in <button>Attach file</button> or drag and drop
    'attach_file': 'Attach file',
    'autogiro_transfer_info': 'When transferring via direct debit, we will collect the money directly from your account within 1–3 banking days. This means you need to have sufficient funds in the account you have designated when registering for direct debit. If you do not have sufficient funds, we will make a second attempt at a later date.',
    'await_assignment': 'Waiting for allocation',
    'await_delivery': 'Waiting for delivery',
    'await_match': 'Waiting for match',
    'await_payment': 'Waiting for payment',
    'ba_modal_text': 'In order to offer you and other clients the best possible advice, we ask that you inform your advisor why you did not follow this particular advice. Thank you in advance.',
    'ba_modal_title': 'You chose not to follow the advice we gave you',
    'back_to_start': 'Back to start page',
    'balance': 'Balance',
    'begin_transfer': 'Begin Transfer',
    'beneficial_owner': 'Beneficial owner',
    'beneficial_owners': 'Beneficial owners',
    'block_sale': 'BLOCK SALE',
    'branch': 'Sector',
    'broker_advice_risk_info': <Fragment><p>JOOL Markets uses a 7-point risk scale, in which JOOL’s investments in bonds and direct loans are considered to be high-risk investments and the risk corresponds to 7 out of 7 on this scale</p><p>This is based on the fact that investors may lose more than 25% of their invested amount during a period of one year. Investments also involve exposure to a single company and the risk is not spread over several companies, as with a fund for example. In addition, there is also a liquidity risk as it can be difficult for investors to sell their holding in advance. There may also be a liquidity risk relating to property, for example, when pledged assets are seized. There is also a risk that the issuer may need to refinance their business, and therefore a risk of the issuer being unable to raise new capital before the bond’s expiration date. There is also an interest rate risk, as the investment is exposed to changes in general interest rate levels. There is also a risk that the issuer is unable to pursue operating activities due, for example, to them not having the requisite permissions.</p><p>Further information about risk factors that are specific to your investment are stated in the investment documentation. You can find this under the heading ‘Documents’ and we ask that you also read through all the investment documentation.</p></Fragment>,
    'brokerage': 'Brokerage',
    'capital return': 'Capital Return',
    'buy': 'Buy',
    'ca': 'approx.', // Ex ca 6-8%
    'call_dates': 'Call dates',
    'calyear-0': 'Current year',
    'cancel': 'Cancel',
    'cancelled': 'Cancelled order',
    'case': 'Case',
    'cash': 'Cash',

    'cashflow out': 'Currency Exchange',
    'cashflow in': 'Currency Exchange',
    'change_lang': 'Change Language',
    'charge': 'Charge',
    'checkbox_disclaimer_life': 'That no investment advice has been given. That I have received and read the PRIIPS Key Information Document (KID) containing key information about my JOOL Life insurance. That I have read all the investment documentation (including KID for the investment) and understand the terms and conditions of the offer and the characteristics and risks of the financial instrument. That any partial or full surrender of my insurance could take place by the investment being transferred to me personally. That I accept that my investment may result in loss of part or all of the investment amount, and that my financial position is such that I am able to bear such a loss. That any accrued interest may become due in addition to the amount I need to pay. That there are sufficient funds in my unit-linked insurance for the allocation I receive with this subscription.',
    'checkbox_disclaimer_market': 'That no investment advice has been given. That I have read all the investment documentation and understand the terms and conditions of the offer and the characteristics and risks of the financial instruments. That I accept that my investment may result in loss of part or all of the investment amount, and that my financial position is such that I am able to bear such a loss. That any accrued interest may become due in addition to the amount I need to pay. That there are sufficient funds in my account with JOOL Markets AS for the allocation I receive with this subscription.',
    'choose_payment_method': 'Select way of investing',
    'classification': 'Classification',
    'clearing_number': 'Clearing number',
    'click_here': 'Click here',
    'confirm_bankid': 'Confirm with BankID',
    'close': 'Close',
    'collateral': 'Collateral',
    'company_customer': 'Corporate client',
    'company_registration': 'Company Registration',
    'complete_order': 'Place order',
    'complete_registration_to_see_history': 'Complete the registration to see your portfolio, transactions, and history.',
    'complete_registration_to_see_payment_options': 'Complete the registration to see your payment alternatives.',
    'contact_us_we_get_back': 'Please contact us, we will get back to you as soon as possible.',
    'continue': 'Continue', // OLD: Move on
    'cost correction': 'Correction',
    'cost_fee': 'Brokerage fee',
    'cost_fee_new': 'Fee',
    'cost': 'Cost',
    'costs': 'Costs',
    'country': 'Country',
    'coupon_percentage': 'Coupon rate',
    'coupon': 'Coupon',
    'create_account': 'Create account',
    'create': 'Create',
    'current_events': 'Current events',
    'current_investments': 'Current Offers',
    'customer_diligence_account_opening': 'Know Your Client & Account opening',
    'customer_registration': 'Client registration',
    'death payment': 'Death Payment',
    'delete_draft': 'Delete draft',
    'deposit correction': 'Deposit Correction',
    'deposit_money': 'Deposit money',
    'deposit': 'Premium',
    'deposit - repayment': 'Repayment, Payment',
    'deposits': 'Deposits',
    'deposit - sale': 'Sale, Payment',
    'details': 'Details',
    'development': 'Return',
    'direct_loans': 'Direct loan',
    'distribution': 'Allocation',
    'document': 'Document',
    'documents': 'Documents',
    'download_file': 'Download file',
    'draft': 'Draft',
    'drag_n_drop': 'Click or drop your files here',
    'drop_files_here': 'Drop files here',
    'dropdown_effect_title': 'How does the brokerage fee affect your overall return?',
    'dropzone_text': 'Click or drop your file here', //en: Click or drop your file here
    'economic_situation': 'Financial situation',
    'edit_draft': 'Edit draft',
    'edit': 'Edit',
    'effective_annual_rate': 'Effective annual interest',
    'endowment_title': 'Return by Year',
    'endowment': 'Return by Year',
    'enter_your_ssn': 'Enter your personal identification number',
    'even_posts': 'Items of',
    'exchange': 'Exchange',
    'exchange (issue)': 'Exchange',
    'exchange-rate': 'Latest price',
    'exchange partially': 'Partially Exchange',

    'executed': 'Placed order',
    'facts_and_fees': 'Facts & fees',
    'fetching_securities': 'Retrieving investments...',
    'form_sent': 'Your form has been sent',
    'from_account': 'From account', // used in withdrawal view'
    'from': 'From',
    'given': 'Selected period',
    'go_back': 'Back',
    'goals_economic_situation': 'Objectives & Financial Situation',
    'goals_excess': 'Excess liquidity',
    'goals_speculative': 'Funds earmarked for speculative investments',
    'good_to_know': 'Good to know',
    'help': 'Help', // For the help button
    'hide_info': 'Hide information',
    'high_lowercase': 'high',
    'high': 'High',
    'holding': 'Holdings',
    'hours': 'Hours',
    'how_do_you_wanna_use_the_service': 'What service would you like to use?',
    'how_it_works': 'How it works',
    'icon': 'icon',
    'id': ' ID', // eg id on a security
    'if_it_does_not': 'If it does not,',
    'image_or_video': 'Image or Video',
    'image': 'Image',
    'increase_net_yield': 'Increase your net return with a unit-linked JOOL Life insurance',
    'increase_return': 'Diversify your risks and increase your return together with other investors',
    'increment_in_step': `% in increments of ${v.number}`, // v.number = a step value. ex 0.5 - used in secondary market trade order view
    'interest_interval': 'Interest payment interval',
    'interest_payments': 'Interest payments',
    'interest': 'Interest',
    'invest_now': 'Invest now',
    'invest': 'Invest',
    'investment_amount': 'Investment amount',
    'investment': 'Investment',
    'investments': 'Investments',
    'isin': 'ISIN',
    'issue': 'Issue',
    'issue_date': 'Issue Date',
    'issuer': 'Issuer',
    'key_points': 'Key Points',
    'knowledge_test': 'Knowledge test',
    'lang_en': 'English',
    'lang_fi': 'Finnish',
    'lang_no': 'Norwegian',
    'lang_se': 'Swedish',
    'last_coupon': 'Latest interest payment',
    'last_maturity_date': 'Final maturity date',
    'latest_messages': 'Recent messages',
    'latest_rate': 'Last price', // TODO: Translate
    'learning_material': 'Study material',
    'leave_form_msg': 'Are you sure you want to cancel? In which case the information you entered will not be saved.',
    'legal_advisor': 'Legal advisor',
    'liquid_funds': 'Cash',
    'live': 'Open',
    'loading': 'Loading',
    'login_button': 'Log in with BankID',
    'login_error_alert': 'Something went wrong. Please check your social security number and try again. If the error persists, make sure you have created an account.',
    'login_registration_in_progress': 'Registration is not complete',
    'login_window_opens_automatically': 'The login window will open automatically.',
    'logout_modal_button': 'Extend session',
    'logout_modal_desc': 'You will soon be logged out due to inactivity.',
    'logout_modal_title': 'You will soon be logged out',
    'token_has_expired': 'You have been logged out',
    'token_has_expired_desc': 'You have been logged out due to inactivity.',
    'logout': 'Log out',
    'low': 'Low',
    'market_value': 'Market value',
    'market': 'Market',
    'maturity_date': 'Maturity date',
    'maturity_in': 'Maturity in',
    'max_volume': 'Max. volume',
    'medium_high': 'medium-high',
    'medium': 'Medium',
    'message_advisor': 'Send message',
    'messages': 'Messages',
    'min_investment': 'Min. investment',
    'min': 'Min.', // Like in Minimum investment
    'minimize': 'Minimize', // as in hide all posts
    'minimum_investment': 'Minimum investment',
    'months-3': '3 months',
    'my_accounts': 'My accounts',
    'my_pages': 'My Pages',
    'my-portfolio': 'My portfolio',
    'name': 'Name',
    'need_adequate_funds': 'In order for the transaction to be completed, you need to have sufficient funds in your account by',
    'need_help': 'Do you need help?',
    'new_deposit': 'New deposit',
    'next_coupon': 'Next interest payment',
    'next_question': 'Next question',
    'next_step': 'Next stage',
    'next': 'Next',
    'no_acccounts_available': 'No accounts available',
    'no_data_found_in_period': 'No data found within the chosen time period',
    'no_data_found': 'We could not find any data',
    'no_data_yet': 'There is no data to show yet.',
    'no_events_at_the_moment': 'At the moment you have no current events. Go to the investment page to find current investment offerings.',
    'no_holdings_at_this_point': 'At the moment you don\'t have any investments in your portfolio. Go to the investment page to find current investment offerings.',
    'no_holdings': 'No holdings',
    'no_investments_on_primary_market': 'We do not have any primary issues currently. We are working on new investment possibilities for you.',
    'no_subject': 'No subject',
    'nominal_amount': 'Nominal Amount',
    'notify_at_opening': 'Notify on opening',
    'nprof': 'I wish to be classified as a non-professional client',
    'observe_terms': 'N.B. All conditions are indicative',
    'observe_values': 'N.B. All values are indicative',
    'of': 'of',
    'ongoing_payments': 'Ongoing payments',
    'other_costs': 'Other costs',
    'overview': 'Overview',
    'page_not_found_check_address': <Fragment>Unfortunately the page was not found<strong>{v.path}</strong>. Please check that you have entered the correct address.</Fragment>,
    'page_not_found': 'Unfortunately the page was not found',
    'page': 'Page',
    'part_plural': `Part ${v.number}`, // Ex Part 2
    'part_singular': `Part ${v.number}`, // Ex Part 1
    'pay_now': 'Pay in',
    'pay': 'Pay',
    'payment_options': 'Payment options',
    'payment_reference': 'Payment reference',
    'payment': 'Payment', // Used in PayCard (event cards - ongoing investments)
    'percent': 'Percent',
    'percent_today': '% today',
    'period': 'Period',
    'pnote': 'Direct loan',
    'policy_information': 'Policy Information',
    'policy': 'Policy',
    'prepare_investment': 'Prepare investment',
    'previous': 'Previous',
    'price': 'Price',
    'primary_market': 'Primary market',
    'private_customer': 'Private client',
    'product_page': 'Prod. page',
    'proceeds': 'Return',
    'prof': 'I wish to be classified as a professional client',
    'profile_settings': 'Profile settings',
    'promissory_note': 'Direct loan',
    'proxy': 'Power of attorney',
    'purpose': 'Purpose',
    'questions_plural': `${v.number} questions`, // Ex: 6 frågor
    'questions_singular': `${v.number} question`, // Ex: 1 fråga
    'rate': 'Price', // Val av kurs, ex 98%
    'read_more': 'Read more',
    'rec_period': 'Rec. period',
    'received_assignment': 'You have received an allocation of',
    'records_of_min_investment': `Items of ${v.number}`, // number = blocksize with currency ex "10 0000 SEK"
    'redo_test': 'Take the test again',
    'reduction_of_return': 'Reduction of returns',
    'reload_page': 'Reload page',
    'register_for_autogiro': 'Register direct debit',
    'register_new_company': 'Register a new company',
    'registration': 'Registration',
    'rem_annual_interest_rate': 'Remaining interest',
    'rem_running_time': 'Remaining maturity',
    'remaining_return': 'Remaining return',
    'remaining_running_duration': 'Until maturity',
    'remove_file': 'Remove',
    'remove_row': 'Remove row',
    'remove': 'Remove',
    'repayment': 'Repayment',
    'report_error': 'Report error',
    'report_ins': <Fragment>Please note that this report only contains information about the securities account in your insurance policy with Quantum Leben AG. For complete information about the holdings in your insurance policy please log in to <a href="https://www.clientmanager.se" className="link">www.clientmanager.se</a>. If you have any questions, you can contact <a href="mailto:backoffice@joollife.com" className="link">backoffice@joollife.com</a> or call us on <a href="tel:+31(0)317971936" className="link">+31 (0) 31 - 797 19 36</a>.</Fragment>,
    'report': 'Report',
    'repayment correction': 'Correction - Repayment',
    'result_since_start': 'Return since start',
    'result_this_year': 'Return so far this year',
    'result': 'Return',
    'rows': 'rows',
    'save_and_continue': 'Save and Continue',
    'save_draft': 'Save draft',
    'save_test': 'Save test',
    'save': 'Save',
    'saved_documents': 'Stored documents',
    'saving': 'Saving',
    'secondary_updated_info': 'We want you to know that the information presented in the marketing material of the issue was created in connection with the issuance of the investment in the primary market.',
    'seconds': 'Seconds',
    'sector': 'Sector',
    'securities': 'Securities',
    'security_code': 'Code',
    'security': 'Investment',
    'see_full_report': 'See complete report',
    'see_more_info': 'View additional information',
    'see_investments': 'See all investments',
    'select': 'Select',
    'select_error_increment': `Select a percentage in increments of ${v.number}`, // number = a step value. ex 0.5
    'select_error': 'Please select one or more alternatives',
    'sell_entire_holding': 'Sell entire holding',
    'sell': 'Sell',
    'send': 'Send',
    'sending': 'Sending',
    'settlement_date': "Settlement date",
    'share-of-portfolio': 'Part of portfolio',
    'share': 'Share',
    'shares': 'Shares',
    'show_all': 'Show all', // As in show all posts
    'show_less': 'Show less',
    'final_balance': 'Policy Value Closing Balance ',
    'show_more_items': 'Show more', // As in show more posts
    'show_more': 'Show more',
    'show_productpage': 'Show product page',
    'signatory': 'Signatory',
    'signatories': 'Signatories',
    'skip_to_main_content': 'Skip to main content', // For a11y button to jump to the main content
    'sold_out': 'Sold out',
    'sold': 'Sold', // When a sell trade order has status executed
    'something_went_wrong': 'Something went wrong',
    'split': 'Split',
    'ssn_placeholder': 'yyyymmddxxxx',
    'start_part_plural': `Start part ${v.number}`, // Ex starta del 3
    'start_part_singular': `Start part ${v.number}`, // Ex starta del 1
    'start_test': 'Start test',
    'startpage': 'Start page',
    'status': 'Status',
    'stamp duty': 'Stamp Duty',
    'subscription': 'Subscription',
    'subscription rights issue': 'Subscription rights issue',
    'subscription rights issue (c)': 'Subscription rights issue (C)',
    'structured_product': 'Structured Product',
    'subscription_completed_info': 'Your investment order is received and you will soon be notified about allocation. You can follow your investment under My Accounts.',
    'subscription_completed': 'Subscription completed',
    'subscriptions': 'Subscription application',
    'sum': 'Sum',
    'summary_in_percent': 'Summary in percent',
    'summary': ' Summary',
    'surrender': ' Surrender',
    'surrender sec': ' Surrender, Security',
    'tax': 'Tax',
    'terms': 'Terms and Conditions',
    'test_aptitude': 'Appropriateness test',
    'test_knowledge': 'Knowledge test',
    'test_objective': 'Objectives & Financial Situation',
    'test_terms': 'Terms and conditions & Signing',
    'tests_heading': 'Investor tests',
    'to_account': 'To account', // used in withdrawal view
    'to': 'To',
    'tools': 'Tools',
    'total_fees': 'Total fees',
    // 'total_including_courtage': 'Total ', // total_including_courtage
    including_courtage: '(including courtage)',
    'total_post-cost_fee': 'Total return after brokerage fee',
    'total_pre-cost_fee': 'Total return before brokerage fee',
    'total_value': 'Total value',
    'total_volume': 'Total volume',
    'total': 'Total',
    'trade_amount': 'Amount',
    'trade_amount_new': 'Sum',
    'transaction_fee': 'Transaction fee',
    'transaction': 'Transaction type',
    'transactions_and_notes': 'Historical transactions and notes',
    'transactions': 'Transactions',
    'trustee': 'Trustee',
    'try_again': 'Try again',
    'type_add': 'Posts',
    'type': 'Type',
    'upcoming_investments': 'Upcoming investments',
    'update_report': 'Update report',
    'update': 'Update', // Ex Money origin modal trigger button in /deposit
    'upload_confirmation': <Fragment>We have received your document. You can view your uploaded documents under {v.link}</Fragment>, // link = profilinställningar
    'use_of_capital': 'Use of capital',
    'value': 'Value',
    'value adjustment': 'Value Adjustment',

    'video': 'Video',
    'volume': 'Volume',
    'waitlist': 'Waiting list',
    'withdraw_money': 'Withdraw money',
    'withdrawal': 'Withdrawal',
    'withdrawal - buy': 'Buy, Payment',
    'withdrawal correction': 'Withdrawal correction',
    'withdrawal sec': 'Withdrawal, Security',
    'write_message': 'Write message',
    'yearly-rate': 'Annual interest',
    'years-1': '1 year',
    'yield': 'Return',
    'yield_percent': 'Return in %',
    'yield_currency': `Return`,
    'you_will_see_messages_here': 'Here you will find your future messages.',
    'your_account_balance': 'Your account balance after upcoming transactions:',
    'your_answers': 'Your answers',
    'your_documents': 'Your documents',
    'your_portfolio': 'Your portfolio',
    'zone_currency': 'SEK',

    // Holding types
    'bond': 'Corporate bonds',
    'promnote': 'Promissory Notes', // TODO: translate
    'stock': 'Unlisted stocks',
    'listedstock': 'Listed Stock', // TODO: translate
    'promissory note': 'Promissory note',
    'fund': 'Funds',
    'currency': 'Currency',
    'structured': 'Structured Products', // TODO: translate
    order_process: 'Order process',

    //Markets
    'se': 'Sweden',
    'no': 'Norway',
    'fi': 'Finland',


    // Sidebar helper
    'email': 'Email',
    'tel': 'Tel',

    // Date and time
    'annually': 'Annually',
    'bullet': 'All on maturity date',
    'date': 'Date',
    'day_plural': 'days',
    'day_singular': 'day',
    'day_string_plural': `${v.number} days`, // ${v.number} will be replace with a plural number, ex -10 or 1.5
    'day_string_short_plural': `${v.number} days`, // ${v.number} will be replace with a plural number, ex -10 or 1.5
    'day_string_short_singular': `${v.number} day`, // ${v.number} will be replace with a signular number, ex -1 or 0.5
    'day_string_singular': `${v.number} day`, // ${v.number} will be replace with a signular number, ex -1 or 0.5
    'days': 'Days',
    'in_days_string_plural': `In ${v.number} days`, // ${v.number} will be replace with a plural number, ex -10 or 1.5
    'in_days_string_singular': `In ${v.number} day`, // ${v.number} will be replace with a signular number, ex -1 or 0.5
    'minutes': 'Minutes',
    'month_string_plural': `${v.number} months`, // ${v.number} will be replace with a plural number, ex -10 or 1.5
    'month_string_short_plural': `${v.number} month`, // ${v.number} will be replace with a plural number, ex -10 or 1.5
    'month_string_short_singular': `${v.number} month`, // ${v.number} will be replace with a signular number, ex -1 or 0.5
    'month_string_singular': `${v.number} month`, // ${v.number} will be replace with a signular number, ex -1 or 0.5
    'month': 'month',
    'quarterly': 'Quarterly',
    'semi_annually': 'Semi-annually',
    'time_left_to_open': 'Time remaining until opening',
    'year_string_plural': `${v.number} years`, // ${v.number} will be replace with a plural number, ex -10 or 1.5
    'year_string_singular': `${v.number} years`, // ${v.number} will be replace with a signular number, ex -1 or 0.5
    'year': 'years',

    // Money
    'millions_string': `${v.number} M${v.currency}`, // ex: 123 MSEK, ${v.number} - value in millions, ${v.currency} - the current currency
    'percent_per_year': `${v.number}% per year`, // number = procent. ex 10% per year

    // Login view
    'become_customer': 'Become a client',
    'choose_account_login': 'Select account to log in with',
    'companies': 'Companies',
    'login_company_registration_in_progress': 'Company registration is not complete',
    'login_failed_message': 'Login failed',
    'login_text': 'When you have logged in, you can lorem ipsum sociis natoque penatibus et magnis dis parturient montes.',
    'login': 'Log in',
    'private': 'Private',
    'terms_conditions': 'Terms and conditions & Signing',
    'welcome_text': 'Welcome to JOOL',
    welcome_info: 'Ditt konto är nu redo att användas. Om du har några frågor kan du alltid klicka på frågetecknet nere till höger.',
    'choose_login_option': 'Choose login option',
    'bankid_mobile': 'Mobile Bankid',
    'bankid_device': 'Bankid device',
    'login_to_slow': 'You were too slow to sign in. You need to complete the login within 2min from the time you started logging in.',
    bankid_title: 'Open BankID on your device',
    bankid_open: 'Open BankID on this device',
    bankid_start: `Please open bankID on your device and sign towards ${v.platform}`,
    bankid_button: 'Open BankID on this device',
    bankid_abort: 'Abort signing',
    bankid_error: <>Something went wrong when signing in. Please close down and try again. If this problem continues, please contact our <a href={`mailto:${v.email}`}>support</a></>,
    registered_user_title: 'Welcome back!',
    registered_user_text: 'You already have an account at Hubins. Click on the button below to reach your pages:',

    // Friendly reminder
    'fr_description': 'There are a few more steps to complete before you can use the service. You can complete these stages at any time.',
    'fr_title': 'You’re done!',
    'yearly_reminder': 'Yearly reminder',

    // Logout view
    'logging_out': 'Logging out',
    'logout_description': 'Redirecting you to the start page',
    'logout_title': 'You have been logged out',

    // Deposit / Withdrawal view
    // New

    'deposit_details': 'Deposit details',
    'deposit_details_text': 'Specify account and what amount you want to deposit.',
    // 'money_origin_title': 'Where do your securities or money come from?',
    // 'money_origin_text': 'Are your answers given at the time of registration still correct?',
    // 'demand_assesment_text': `You completed your Demand assessment ${v.date}. If your demand has changed since this date, please update your current demand.`,

    // Old: check if need's to be removed
    'autogiro_agree': 'I accept the terms and conditions for payment via direct debit',
    'autogiro_sub_description': <Fragment>If you would like to change your registered account, please contact your broker or our client service on <a className="link" href="tel:0317971936">031-797 19 36</a></Fragment>,
    'autogiro_subtitle': 'Your registered account',
    'autogiro_terms': 'Terms and conditions for payment via direct debit',
    'autogiro_title': 'You have not registered an account for direct debit',
    'available_for_withdrawal_info': 'In your unit-linked insurance, you need to have sufficient funds to cover future costs, corresponding to around 6 months.',
    'available_for_withdrawal': 'Available for withdrawal',
    'bank_transfer_bankgiro': 'Bankgiro/Bank transfer',
    'bank_transfer': 'Bank transfer',
    'bank': 'Bank',
    'bank fee': 'Bank Fee',
    'bankgiro_number': 'Bankgiro number',
    'bankgiro_payment': 'Bankgiro payment',
    'bankgiro': 'Bankgiro',
    'conducts_deposit': 'Make deposit',
    'deposit_money_ins': <Fragment>In order to deposit money in your insurance securities account, you must download the additional premium form and send it to <a className="link" href="mailto:backoffice@joollife.com">backoffice@joollife.com</a>. You can find this form when you log in to <a className="link" href="https://www.clientmanager.se">www.clientmanager.se</a> and press Library. If you have any questions, you can contact <a className="link" href="mailto:backoffice@joollife.com">backoffice@joollife.com</a> or call us on <a className="link" href="tel:+31(0)317971936">+31 (0) 31 - 797 19 36</a>.</Fragment>,
    'deposit_payment_option_desc': 'If you want to change payment options, you can do so under your profile settings.',
    'deposit_payment_settings_link': 'Change payment option',
    'direct_debit': 'Direct debit',
    'documents_terms': 'User Agreement and Terms & Conditions',
    'sign_bankid': 'Sign using BankID',
    'sign_open_new_window': 'Signing opens in a new window',

    'withdrawal_reason': 'Reason for withdrawal',
    'withdrawal_reason_investment': 'Investment',
    'withdrawal_reason_saving_in_institute': 'Savings in other institutions',
    'withdrawal_reason_consumption': 'Purpose of spending',
    'withdrawal_reason_other': 'Other',
    'confirm_withdrawal': 'Confirm withdrawal',

    'choose': 'Select', // choose a reason for withdrawel
    'conduct_withdrawal': 'Make withdrawal',
    'direct_debit_success_ingress': 'We will debit money from your registered direct debit account within 1–3 banking days. It is therefore important that there are sufficient funds in your account.',
    'direct_debit_success_title': 'Transfer via direct debit registered',
    'faq_a1': 'The time it takes for the deposit to appear in your account varies depending on the payment options selected and your bank. From the date you initiate a deposit via your bank, it takes 1–3 working days for the money to appear in your account.',
    'faq_q1': 'When will the money appear in my account?',
    'FAQ': 'FAQs',
    'market_buy_success_ingress': <Fragment><p>Your order will be active for 1 month. We will inform you if a matching seller is found.</p><p>Track the status of your order under My Accounts.</p></Fragment>,
    'market_buy_success_title': 'Buy order registered',
    'market_sell_success_ingress': <Fragment><p>Your order will be active for 1 month. We will inform you if a matching buyer is found.</p><p>Track the status of your order under My Accounts.</p></Fragment>,
    'market_sell_success_title': 'Sell order registered',
    'monthly': 'Monthly',
    'new_withdrawal': 'Withdrawal',
    'wd_info_header': 'Withdrawals are made to your pre-registered account',
    'wd_info_text': <Fragment>If you would like to change your registered account, please contact your broker or client service on <a href="tel:031-797 19 36">031-797 19 36</a></Fragment>,
    'withdrawal_accept_terms_text': 'I confirm and accept that the money will go to my registered account.',
    'withdrawal_reason_text': 'Enter reason for withdrawal',
    'withdrawal_success_ingress': `Your withdrawal has been registered in your account with ${v.app_name}.You will see the money in your registered account within 2–3 working days.The time may vary depending on your bank.`,
    'withdrawal_success_title': 'Withdrawal completed',

    dropdowneffect_desc: 'Please note that we only considerate brokerage fee in this illustration',

    // Transfer Instructions
    'bank_details': 'Bank details',
    'list_of_banks_text': 'Here are links to the most common banks in Sweden',
    'payment_amount': 'Payment amount',
    'pi_from_bank': 'Note that your payment will be made out to SIP NORDIC AB.', // payment instruction from what bank the money will be tranfered to
    'ti_info': `When everything is completed, the deposit will appear in your account ${v.name} `, // name - the account name
    // 'ti_step1': 'Log in to your bank',
    // 'ti_step2': 'Select new payment and payment method',
    // 'ti_step3': 'Enter bank details and the reference number',
    // 'ti_step4': 'Make the payment',
    'payment_instructions': 'Gå till din internetbank och genomför insättning med uppgifterna nedan.', // TODO: Translate

    // Money origin (also used in income source in registration)
    'previous_investments': 'Previous investments',
    'inheritance_gift': 'Inheritance/Gift',
    'property_sale': 'Sale of property',
    'insurance_payout': 'Insurance payout',
    'dividend': 'Dividend',
    'dividend as shares': 'Dividend as Shares',
    'salary_pension': 'Salary/Pension',
    'lottery_gaming': 'Lottery/Gambling',
    'corporate_sales': 'Company sale',
    'share_dividend_from_own_company': 'Share dividend from own company',
    'profit correction': 'Profit Correction',

    'profits_daily_operations': 'Profit from daily operations',
    'dividend_associated_comp': 'Share dividend from associate company',
    'money_origin_other': 'Other',

    'income_source': 'Where do your securities or money come from?',
    'company_income_source': 'Where do the company’s securities or money come from?',
    'income_source_text': 'Are your answers given at the time of registration still correct?',

    // Buy /sell market bonds
    'available_for_purchase': 'Cash',
    'blocksize': 'Traded in blocks of',
    'bonds_amount': 'Number of items',
    'company_bonds_amount': 'Number of bonds',
    'buy_bonds': 'Buy bonds',
    'choose_account': 'Select account',
    'choose_amount': 'Initial investment amount',
    'choose_investment': 'Select investment',
    info_about_fund: 'Information about the bond',
    info_about_stock: `Information about the stock`, // TODO: Översätt onoterad aktie
    'jool_life_terms': 'That no investment advice has been given. That I have received and read the PRIIPS Key Information Document (KID) containing key information about my JOOL Life insurance. That I have read all the investment documentation (including KID for the investment) and understand the terms and conditions of the offer and the characteristics and risks of the financial instrument. That any partial or full surrender of my insurance could take place by the investment being transferred to me personally. That I accept that my investment may result in loss of part or all of the investment amount, and that my financial position is such that I am able to bear such a loss. That any accrued interest may become due in addition to the amount I need to pay. That there are sufficient funds in my unit-linked insurance for the allocation I receive with this subscription.',
    'marketorder_terms': 'That no investment advice has been given. That I have read all the marketing material, all the investment documentation and understand the terms and conditions of the offer and the characteristics and risks of the financial instrument. That the product I have invested in is deemed to have the highest risk level of 7 on a scale of 7, and is a high-risk product. That as a client, I accept that my investment may result in loss of part or all of the investment amount, and that as a client my financial position is such that I am able to bear such a loss. That there are, or will be sufficient funds in my account with JOOL Markets AS for the allocation I receive with this subscription.',
    'order_validity_terms': 'Please note that accrued interest may be payable.',
    'order_validity': 'Validity of order',
    'ordering': 'Placement of order',
    'remaining_rate': 'Remaining interest',
    'sell_bonds': 'Sell holdings',
    'sell_terms': 'That no advice has been given for this sell order. That I want to sell my participation in the holding in the above-stated securities and that I understand the characteristics of this sell order and the costs associated with this sale. That I understand that JOOL Markets AS will offer a secondary market for bonds on a best-efforts basis.',
    'transaction_fee_terms_tooltip': <Fragment><p>When buying investments on the secondary markets accrued interest may be added to cost for the buyer if the security is a bond or a similar interest-bearing security. The amount is equal to the interest that has not yet been paid out since the last occurring interest payment date and is calculated up and to the date of the trade. The interest is based on the nominal amount, consequently to a price of 100%.</p><p><strong>In practice that means that the buyer of the asset is paying for the accrued interest but will receive the whole interest-payment at the next interest-payout date, including the accrued interest.</strong></p><p>Additional fees from the product-company, such as selling-fee, may be applied. Please read the terms and conditions for the specific investment for more information.</p></Fragment>,
    'transaction_fee_terms': 'Please note that accrued interest and additional fees from the product company may be applied to the transaction.',
    'fees correction': 'Fees Correction',
    //Alerts
    'autogiro_clarification': 'For direct debit payments, the money will be automatically withdrawn from your pre-registered account.',
    'autogiro_error_alert': `Something has happened with your direct debit connection to us for account "${v.name}".Please contact us on + 46(0)31 - 797 19 36 or at ${v.email} `,
    'autogiro_waiting_alert': `We are currently processing your direct debit application for "${v.name}"`,
    'cancel_autogiro_modal': 'To cancel your direct debit, please contact your bank or log in to your online bank',
    'cancel_autogiro': 'Cancel direct debit permanently',
    'company_registration_in_progress': 'Your company registration is currently being processed by JOOL. We will inform you when it is complete.',
    'not_all_signatories_signed': 'Not all signatories/beneficial owners have signed the power of attorney.', //Company login
    'yearly_reminder_alert': '',

    //JOOL contact info
    'jool_email': 'kundservice@jool.se', // depricated. use platform_email instead
    'jool_support_email': 'kundservice@jool.se',  // depricated. use platform_email instead
    'jool_tel': '031-797 19 36', // depricated. use platform_tel instead

    // Broker Advice
    // 'accept_terms_and_proposal': 'Accept terms & conditions and the proposal',
    // 'broker_advice':
    //   <Fragment>
    //     <p>Based on the information you submitted during the registration process, JOOL advises you to invest {v.amount} in {v.name}</p>
    //     <p>You have stated that you would like to see an annual return of between {v.expected_return_min}% and {v.expected_return_max}% on your investments with JOOL. Furthermore, you have stated that your investment horizon for individual investments with JOOL Markets is between {v.term_min} and {v.term_max} months, and that you wish to have an overall investment horizon of between {v.investment_horizon_min} and {v.investment_horizon_max} years for your entire portfolio with JOOL Markets.</p>
    //     <p>{v.name} has {v.s_coupon}% in annual interest and a maturity of {v.period_in_months} months, which is within the range of your investment preferences and therefore suitable for you.</p>
    //     <p>You would like a high risk on your individual investments to provide the opportunity for a high return, and you are prepared and accept that you may lose the entire invested amount. You are aware and accept that {v.name} is considered to generate a high return and is classified as a high-risk investment. Based on the above, the risk level of this investment is suitable for you.</p>
    //     <p>The funds you are investing amount to {v.goalsText} and constitute approximately {v.investable_percentage} of your investable capital, which is considered suitable as this enables a diversification of risk and ensures you are able to bear a potential loss. Your financial position is considered to be strong and any potential loss of the entire value of the product is not deemed to have a significant impact on your financial situation. </p>
    //     <p>Based on the information you have submitted, combined with the completed knowledge test, JOOL classifies your knowledge of the investments that are mainly offered by JOOL Markets, ie high-risk investments in the categories of listed and unlisted corporate bonds and direct loans as {v.suitability_investment_experience}.</p>
    //     <p>Overall, JOOL is of the opinion that you have sufficient knowledge and experience to understand the risks and characteristics associated with the investments that JOOL offers.</p>
    //     <p>The cost of this transaction is a brokerage fee of {v.courtage_percent}, corresponding to {v.courtage_amount}. This means that throughout the entire investment period, you will be expected to gain {v.profit_percentage} in interest after costs. The decrease in the total return over the entire investment period corresponds to {v.reduced_profit}</p>
    //     <p>Please note that, depending on where the holding is held, taxes and costs linked to the transaction may be payable.</p>
    //     <p>{v.description}</p>
    //     <p>To summarise, an investment of {v.amount} in {v.name} is suitable for you, given your financial situation and level of knowledge and experience. It is also suitable since the characteristics of the investment fall within the range of your preferences with regard to return, maturity and risk appetite.</p>
    //   </Fragment>,

    // 'broker_advice_company':
    //   <Fragment>
    //     <p>Based on the information you submitted during the registration process, JOOL advises the company to invest {v.amount} in {v.name}</p>
    //     <p>You have stated that the company would like to see an annual return of between {v.expected_return_min}% and {v.expected_return_max}% on the company’s investments with JOOL. Furthermore, you have stated that the company’s investment horizon for individual investments with JOOL Markets is between {v.term_min} and {v.term_max} months,
    //     and that the company wishes to have an overall investment horizon of between {v.investment_horizon_min} and {v.investment_horizon_max} years for the company’s entire portfolio with JOOL Markets.</p>
    //     <p>{v.name} has {v.s_coupon}% in annual interest and a maturity of {v.period_in_months} months, which is within the range of the company’s investment preferences and therefore suitable for the company.</p>
    //     <p>The company would like a high risk on the company’s individual investments to provide the opportunity for a high return, and the company is prepared and accepts that the company may lose the entire invested amount. You are aware and accept that {v.name} is considered to generate a high return and is classified as a high-risk investment. Based on the above, the risk level of this investment is suitable for the company.</p>
    //     <p>The funds that the company is investing amount to {v.goalsText} and constitute approximately {v.investable_percentage} of the total investment amount that is intended to be invested via JOOL during the current 12 month period, and around {v.total_company_assets_percentage} of the company’s total assets. This is considered to be suitable, since it allows for diversification of risks and ensures that the company is able to bear a potential loss. The company’s financial position is considered to be strong and any potential loss of the entire value of the product is not deemed to have a significant impact on the company’s financial situation. </p>
    //     <p>Based on the information you have submitted, combined with the completed knowledge test, JOOL classifies your knowledge of the investments that are mainly offered by JOOL Markets, ie high-risk investments in the categories of listed and unlisted corporate bonds and direct loans as {v.suitability_investment_experience}.</p>
    //     <p>Overall, JOOL is of the opinion that you have sufficient knowledge and experience to understand the risks and characteristics associated with the investments that JOOL offers.</p>
    //     <p>The cost of this transaction is a brokerage fee of {v.courtage_percent}, corresponding to {v.courtage_amount}. This means that throughout the entire investment period, the company will be expected to gain {v.profit_percentage} in interest after costs. The decrease in the total return over the entire investment period corresponds to {v.reduced_profit}</p>
    //     <p>Please note that, depending on where the holding is held, taxes and costs linked to the transaction may be payable.</p>
    //     <p>{v.description}</p>
    //     <p>To summarise, an investment of {v.amount} in {v.name} is suitable for the company, given the company’s financial situation and level of knowledge and experience. It is also suitable since the characteristics of the investment fall within the range of the company’s preferences with regard to return, maturity and risk appetite.</p>
    //   </Fragment>,
    // 'broker_advice_terms': 'That I have read all the marketing material, all the investment documentation and understand the terms and conditions of the offer and the characteristics and risks of the financial instrument. That the product I have invested in is deemed to have the highest risk level of 7 on a scale of 7, and is a high-risk product. That I accept that my investment may result in loss of part or all of the investment amount, and that my financial situation is such that I am able to bear such a loss. That there are sufficient funds in my account with JOOL Markets AS for the allocation I receive with this subscription.',
    // 'deny_proposal': 'Reject proposal',
    // 'investment_advice': 'Investment advice',
    // 'investment_amount_total': 'Total investment amount',
    // 'investment_information': 'Investment details',
    // 'min_amount_x': `Minimum ${v.amount} `, // Minimum 10 000 SEK
    // 'risk': 'Risk',

    'risk premium': 'Risk Premium',
    // 'tests_need_update_info':
    //   <Fragment>
    //     <p className="clarification">Your information have been updated and do not need to be re-entered until <strong className="c-black">{v.date}</strong></p>
    //     <p className="clarification">If you wish to update your information, please email <a className="link" href={`mailto: ${v.email} `}>{v.email}</a> or<br /> call us on <a className="link" href="tel:{0317971936}">031-797 19 36</a></p>
    //   </Fragment>,
    // 'running_time': 'maturity',
    // 'your_investment_advice': 'Your investment advice',

    // Error messages
    'correct_data_not_loaded': 'At the moment we cannot retrieve the correct data. Please try to log out and log in again.',
    'bad_network': 'There appears to be something wrong with your internet connection. Try reloading the page.',
    'checkbox_error': 'Compulsory in order to continue',
    'dropdown_error': 'Select one of the alternatives',
    'empty_rows': 'No data available', // Ex when rows missing in expandable table
    'error_amount': 'Enter amount',
    'error_email': 'Enter correct email address',
    'error_empty': 'Complete the empty field',
    'error_form': 'Please review the form for any errors',
    'error_max_value': `Enter a value of less than ${v.number} `, // Number is the provided max value
    'error_min_value': `Enter a value of more than ${v.number} `, // Number is the provided min value
    'error_number': 'Please enter a number',
    'error_not_enough_data': 'Insufficient data', // Example diagram that has not data
    'error_options': 'Select one of the alternatives',
    'error_pattern': `Enter a valid value.The valid character is ${v.pattern} `, // pattern is the provided pattern
    'error_step': ` ${v.value} `, // pattern is the provided pattern, eg 10 or 15, instead of 10.2 or 15.8
    'error_underaged': 'Unfortunately you must be over the age of 18',
    'input_error': 'Complete the empty field',
    'no_documents': 'No documents found', // is shown in the <Documents> component ex on /settings#documents
    'ssn_validation_error': 'Incorrect personal identity number (YYYYMMDDXXXX)',
    'org_validation_error': 'Incorrect org. number (XXXXXX-XXXX)', // TODO: translate correctly
    'standard_error_message': 'Something has gone wrong',

    // Statuses
    status_reg_comp: 'Register company',
    status_fetching_holdings: 'Fetching holdings',

    // Dashboard
    'type_buy': 'Buy', // type of trade order
    'type_deposit': 'Deposit', // type of trade order
    'type_sell': 'Sell', // type of trade order
    'type_withdrawal': 'Withdrawal', // type of trade order

    'await_delivery_deposit': 'Waiting for deposit',
    'buy_course': 'Buy price',
    'depositcard_directdebit_info': 'The amount will be withdrawn from your account within a few days. Make sure there are sufficient funds in your account.',
    'directdebit_payment': 'Direct debit payment',
    'executed_deposit': 'Deposit made',
    'expire': 'Expire',
    'payment_info': 'Payment info',
    'purchase_price': 'Buy Price',
    'sell_course': 'Sell price',
    'total_amount_brokerage': 'Total amount (including brokerage fee)',
    'total_amount': 'Total amount',
    'view_advice': 'View proposal',
    'view_productpage': 'View product page',

    // Event Cards
    premium: 'Premium',

    buy_open_title: 'Order recieved',
    buy_open_text: 'Er order är mottagen av försäkringsbolaget.',
    buy_accepted_title: 'Order recieved',
    buy_accepted_text: 'Er order är mottagen av försäkringsbolaget.',
    buy_executable_title: 'Order sent',
    buy_executable_text: "Er order är skickad.",
    buy_executed_title: "Genomförd",
    buy_executed_text: "Er order är genomförd",
    buy_executed_withoutdate_title: 'Paid investment order',
    buy_executed_withoutdate_text: 'Your order has been paid. Waiting for delivery of bonds',
    sell_accepted_title: 'Order recieved',
    sell_accepted_text: '"Er order är mottagen av försäkringsbolaget.",',
    sell_executable_title: 'Order sent',
    sell_executable_text: "Er order är skickad.",
    sell_executed_title: "Order genomförd",
    sell_executed_text: 'Er order är genomförd.',
    sell_executed_withoutdate_title: 'Money recieved',
    sell_executed_withoutdate_text: 'Er order är genomförd.',
    add_open_title: 'Premium in-kind registered',
    add_open_text: 'Your request to transfer assets is registered',
    add_accepted_title: 'Premium in-kind registered',
    add_accepted_text: 'Your request to transfer assets is registered',
    add_executed_title: 'Premium in-kind registered',
    add_executed_text: 'Your transfer is registered',
    add_executed_withoutdate_title: 'Premium in-kind registered',
    add_executed_withoutdate_text: 'Your transfer is registered',
    premium_open_title: 'Payment',
    premium_open_text: 'You have stated that you will make a premium deposit into your insurance policy.',
    premium_accepted_title: 'Payment',
    premium_accepted_text: 'You have stated that you will make a premium deposit into your insurance policy.',
    premium_executed_title: 'Premium in-kind registered',
    premium_executed_text: 'Your transfer is registered',
    premium_executed_withoutdate_title: 'Payment recieved',
    premium_executed_withoutdate_text: 'Your payment is issued and booked in to your policy',
    'premium security': 'Premium Security',
    // Interest indicator
    'ii_up': 'Positive', // Utveckling: Positiv
    'ii_down': 'Negative', // Utveckling: Negativ
    'ii_middle': 'No development ', // Utveckling: Ingen utveckling

    // Yearly Checkup
    'yc_title': 'Yearly required update',  // TODO: translate
    'yc_desc_warning': <><p><strong>Lorem impsum Enligt lagen xxxx så måste vi varje år be dig svara på lorem ipsum för att lorem ipsum.</strong></p><p><strong>Vänligen ta dig tid att fylla i alla svar nedan.</strong></p></>,  // TODO: translate
    'yc_desc_locked': <><p><strong>Lorem ipsum Du måste göra detta innan du kan fortsätta använda tjänsten. Enligt lagen xxxx så måste vi varje år be dig svara på lorem ipsum för att lorem ipsum.</strong></p></>,  // TODO: translate

    'yc_kt_action': 'Do the knowledge test',
    'yc_kt_desc': 'Lorem ipsum. You need to do the knowledge test again. If you dont get it right the first time you will get another try',
    'yc_kt_error': 'You need to pass the knowledge test in order to proceed',
    'edit-org-form': 'Edit original form',
    'yc_locked_risk_text': <>If you wish to change your answer. Please contact {v.platform} at {v.email}</>,
    'yc_success_title': 'Thank you!',
    'yc_success_desc': <p>Your profile has been updated. Now you can continue to use the plattform as usual.</p>,
    'do_later': 'Close and do later',
    'access_law': 'Asset class',
    'asset management fee': 'Asset Management Fee',
    'market_value_start': 'Policy Value Start Balance',
    janoojun: 'Deposits Jan-Jun',
    jultodec: 'Deposits Jul-Dec',
    dev_during_period: 'Return',
    whole_period: 'The whole period',
    the_currency: 'Currency',
    unit_price: 'Price per unit',
    'transaction charge ql1': 'Transaction Fee, Quantum',
    'transaction charge ql2': 'Transaction Fee, Outsourced Services',
    'management fee ifa': 'Management Fee, Intermediary',
    'management fee other ifa': 'Management Fee, Previous Intermediary  ',
    'management fee ql': 'Management Fee, Quantum',
    'management fee ql2': 'Management Fee, Outsourced Services',
    'mature': 'Mature',
    'fixed policy cost ql': 'Fixed Cost, Ouantum',
    'fixed policy cost ql2': 'Fixed Cost, Outsourced Services',
    'debpay': 'Debt Payment',
    'yearly_report': 'Yearly report',
    'yearly_report_header_title': 'Log in to see your annual report',
    password_length_validation: 'Your password needs to contain atleast 6 characters',
    'repeat_password_validation': 'Your password and the repeated password dosnt match',
    'common_error_validation': 'Something went wrong. Please try again',
    password: 'Password',
    email_password_validation_error: 'Username or password is incorrect',
    tfa_validation_error: 'The code is wrong or the session has ended. Please try again or reload the login page',
    activation_success: 'You can now log in with your new password.',
    activation_success2: 'You can now log in to see your annual report.',
    to_login: 'To the login page',
    something_went_wrong_activation_title: 'Something went wrong',
    something_went_wrong_activation_text: <p>
      Please try again or contact us at <a href="mailto:kundservice@quantumleben.se" target="_blank" rel="noreferrer">kundservice@quantumleben.se</a> or <a target="_blank" href="tel:031-797 19 39" rel="noreferrer">031-797 19 39</a>
    </p>,
    activation_no_user_found: 'Something has gone wrong!',
    activation_no_user_found_text: <p>
      It might be that you have already activated your account and then you can click on the link below or go to the web address <a href="https://quantumleben.se">quantumleben.se</a>.
      If this won´t work, please contact us at <a href="mailto:kundservice@quantumleben.se" target="_blank" rel="noreferrer">kundservice@quantumleben.se</a> or <a target="_blank" href="tel:0317971939" rel="noreferrer">031-797 19 39</a>
    </p>,
    activate_account: 'Activate account',
    repeat_password_label: 'Repeat password',

    insurance_fees: 'Insurance Costs and Charges',
    repurchase_value: 'Encashment Value',
    total_insurance_fees: 'Total Insurance costs and charges',
    one_time_fee_total: 'One-time charges: Transaction charge',
    running_fixed_cost_total: 'On-going charges: Fixed Policy Fee',
    running_variable_cost_total: 'On-going charges: Management Policy Fee',
    running_cost_riskpre_total: 'On-going charges: Risk Premium (from 65 years of age)',
    insurance_fees_table_2_intro: 'How have the return of the insurance policy been affected by the policy costs and charges?',
    total_yield_without_fees: 'Total return without any insurance policy costs and charges',
    total_fees_and_costs: 'Total policy costs and charges',
    total_yield_after_fees_and_costs: 'Total return after total insurance policy costs and charges',
    'important_information': 'Important information',
    report_intro_text_original: <>
      Rapporten ger en översikt över ert innehav och dess utveckling under året. Den innehåller även information om kostnader och avgifter kopplade till kapitalförsäkringen. Hubins ansvarar inte för eventuella felaktigheter i informationen. Vid frågor är ni varmt välkomna att kontakta oss på{" "}
      <a
        href="mailto:kundservice@quantumleben.se"
        target="_blank"
        rel="noreferrer"
      >
        kundservice@quantumleben.se
      </a>{" "}
      eller{" "}
      <a target="_blank" href="tel:0317971939" rel="noreferrer">
        031-797 19 39
      </a>
    </>,
    'report_intro_text_ql':
      <p className="clarification">
        <strong>Important information</strong><br />
      In this report, you as a client, will receive information about costs and charges that are connected to your unit-linked insurance. You can at anytime request an itemised breakdown of the costs and charges, or if you have questions regarding the calculations or anything else, by emailing us at <a href="mailto:kundservice@quantumleben.se" target="_blank" rel="noreferrer">kundservice@quantumleben.se</a> or by phone <a href="tel:0317971939" target="_blank" rel="noreferrer">031-797 19 39</a>.
      </p>,
    'report_intro_text_ql_report':
      <p className="clarification">
        <strong>Important information</strong><br />
      You can at anytime request an itemised breakdown of the costs and charges, or if you have questions regarding the calculations or anything else, by emailing us at <a href="mailto:kundservice@quantumleben.se" target="_blank" rel="noreferrer">kundservice@quantumleben.se</a> or by phone <a href="tel:0317971939" target="_blank" rel="noreferrer">031-797 19 39</a>.
      </p>,
    username: 'Username',
    "warning": "Warning",
    "wish_to_change_form": "If you wish to go back and change previous answers, your current changes in this form will not be saved.",
    liquid_account: 'Cash account',
    debt_account: 'Debt account',
    current_holdings: 'Current holdings',
    'forgot_password': 'Forgot password?',
    'reset_password_error': <p>Something went wrong. Please try again or contact us at <a href="mailto:kundservice@quantumleben.se" target="_blank" rel="noreferrer">kundservice@quantumleben.se</a> or <a target="_blank" rel="noreferrer" href="tel:0317971939">031-797 19 39</a></p>,
    'request_reset': 'Reset password',
    'reset_success': 'Check your email',
    'reset_password_success': 'Success!',
    'reset_success_text': 'If you have a registered email with us u should have received a link reset your password',
    reset_password_success_text:
      "Now you can login with your new password",
    'reset_password_header': 'Forgot password',
    'hubins_backoffice': 'Hubins Backoffice',
    'hubins_compliance': 'Hubins Compliance',
    'quantum_backoffice': 'Quantum Backoffice',
    'quantum_compliance': 'Quantum Compliance',
    // For debugging - don't translate
    'test': 'Test se',
    'a_policy_1': 'Policy information',
    'a_policy_2': 'Demand and needs Assesment',
    'a_policy_3': 'Know your customer',
    'a_policy_4': 'Connections',
    'a_policy_5': 'Transactions',
    'a_policy_6': 'Source of Funds',
    'a_policy_7': 'Enhanced due diligence',
    'a_policy_verdict': 'Policy application approval ',

    'sof_status_denied': 'Denied',
    'sof_status_proof_needed': 'Proof needed',
    'sof_status_complete': 'Approved',
    'sof_status_locked': 'Locked',
    'sof_status_': '',

    'transaction_type_pre': 'Premium deposit',
    tfa_code: 'Code',
    tfa_verify: 'Verify code',
    tfa_required_title: 'Activate two factor authentication',
    tfa_required_text: 'You will need to activate two factor authentication in order to be able to keep using this service.',
    tfa_required_text_2: 'Go to settings and activate the two step authentication.',
    settings: "Settings",
    total_insurance_fees_ql: "Total ersättning till Quantum Leben AG",
    total_insurance_fees_hubins:
      "Total ersättning till Hubins för utkontrakterade tjänster åt Quantum",
    total_insurance_fees_hubins_1:
      "Total ersättning till Hubins för försäkringsdistribution",
    total_insurance_fees_other:
      "Total ersättning till tidigare försäkringsdistributör, ej Hubins",
    total_insurance_from_third_party:
      "Total ersättning som Hubins har mottagit från annan tredjepart än Quantum Leben AG (Ex. från fondbolag eller annat produktbolag)",
    total_insurance_to_third_party:
      "Total ersättning som Hubins har tillhandahållit till tredjepart",
    one_time_fee_ql: "Varav ersättning till Quantum Leben AG",
    one_time_fee_hubins:
      "Varav ersättning till Hubins för utkontrakterade tjänster åt Quantum",
    running_fixed_cost_ql: "Varav ersättning till Quantum Leben AG",
    running_fixed_cost_hubins:
      "Varav ersättning till Hubins för utkontrakterade tjänster åt Quantum",
    running_variable_cost_ql: "Varav ersättning till Quantum Leben AG",
    running_variable_cost_hubins:
      "Varav ersättning till Hubins för utkontrakterade tjänster åt Quantum",
    running_variable_cost_hubins1:
      "Varav ersättning till Hubins för försäkringsdistribution",
    running_cost_riskpre_ql: "Varav ersättning till Quantum Leben AG",
    insurance_fees_section_1:
  <>
    <p>
      Hubins vill även göra dig uppmärksam på följande: <br />
      Den totala försäkringsavgiften och försäkringskostnaden under perioden
      beräknas som summan av alla engångsavgifter i form av
      transaktionsavgifter, den fasta löpande avgiften, den rörliga löpande
      avgiften samt riskpremien som har belastat kapitalförsäkringen
      under vald period enligt ovan. För information om tidigare år vänligen kontakta
      kundservice.
    </p>
  </>
    ,
    insurance_fees_section_2:
  <>
    <p>
      Dessa avgifter fördelas sedan i form av ersättning till samtliga
      parter, exempelvis försäkringsbolaget Quantum Leben AG eller
      försäkringsdistributören Hubins AB. Dessa avgifter och kostnader samt
      ersättningar presenteras i tabellen ”Försäkringsavgifter och
      försäkringskostnader”. Här informeras det även att Hubins AB inte
      mottar någon ersättning från annan tredjepart än Quantum Leben AG (ex.
      från fondbolag eller annat produktbolag) samt att Hubins AB inte
      tillhandahåller någon ersättning till annan tredjepart. Eventuella
      ersättningar till tidigare försäkringsdistributörer (ej Hubins) anges
      också i denna tabell.
    </p>
    <p>
      Vänligen notera att ovan beskrivning av försäkringsavgifter och
      försäkringskostnader är generellt och de faktiska
      försäkringsavgifterna och försäkringskostnaderna kan komma att skilja
      sig i procent från ovan.
    </p>
    <p>
      Tabellen nedanför har för avsikt att illustrera hur
      försäkringsavgifter och försäkringskostnader reducerar avkastningen
      för vald period enligt ovan. Här visas även den totala avkastning före och efter årets
      försäkringsavgifter och försäkringskostnader. Denna illustration ska
      sätta de totala försäkringsavgifterna och försäkringskostnaderna i
      relation till investeringens avkastning samt förtydliga hela avgift-
      och kostnadsstrukturen.
    </p>
  </>,
    reason_for_withdrawal: "Anledning till uttag",
    "policy_application_imported_&_kyc":
    "Försäkringsuppdatering och Kundkännedom",
    yearly_checkup: 'Årsuppdatering',
    repeater_error: 'Vänligen fyll i de tomma fälten',
    percent_error_message: 'Värdet får inte vara större än 100 eller mindre än 0',

    'handle_sources': 'Hantera källor',
    'sources': 'Sources',
    'new_source': 'Ny källa',

    'sof_salary': 'Surplus of salary',
    'sof_property': 'Sale of property',
    'sof_company_financing': 'Company financing',
    'sof_dividend': 'Dividend from own company',
    'sof_company_profits': 'Profit from company',
    'sof_company_profits_investment': 'Profit from investments',
    'sof_company_property': 'Sale of property',
    'sof_company_sale_company': 'Sale of company',
    'sof_company_dividend': 'Dividend',
    'sof_company_other': 'Other',
    'sof_company_direct_investments': 'Direct investments',
    'sof_issue_stock': 'Share issue',
    'sof_issue_bonds_loan': 'Bond issue/Loan',
    'sof_profits_investment': 'Profit from investments',
    'sof_sale_company': 'Sale of company',
    'sof_inheritance_gift': 'Inheritance, gift or other',
    'sof_direct_investments': 'Direct investments',

    'proof_verified': 'Source proven',
    'proof_provided': 'Proof sent in for review',
    'proof_not_provided': 'Source not proven',
    'insufficient_transaction_amount': 'Summan av de valda källorna uppnår ej det valda beloppet av transaktionen. Du kan gå tillbaks och ändra värdet på transaktionen om du önskar sätta in ett lägre belopp.',
    'deposit_instructions_title': 'Insättningsuppgifter',
    'deposit_instructions_text': 'Logga in på din internetbank och genomför insättning med uppgifterna nedan.',
    'investment_stock': 'onoterade aktier',
    'sof_old_source': 'Source of funds (v1)',

    'stock_2_status': 'Genomförd',
    'stock_6_status': 'Betalning genomförd',
    'stock_10_status': 'Tilldelning',
    'stock_8_status': 'Väntelista',
    'stock_4_status': 'Order mottagen',
    'stock_5_status': 'Order mottagen',
    'stock_2_text': 'Investering genomförd - Aktier inbokade',
    'stock_6_text': 'Ni har fått tilldelning - Betalning genomförd',
    'stock_10_text': 'Ni har fått tilldelning - Se till att ha likvid tillgängligt',
    'stock_8_text': 'Väntelista - Ingen tilldelning',
    'stock_4_text': 'Order mottagen - Inväntar tilldelning',
    'stock_5_text': 'Order mottagen - Inväntar tilldelning',
    'stock_1_text': 'Ni har fått tilldelning - Se till att ha likvid tillgängligt',
    'stock_1_status': 'Tilldelning',

    insurance_number: 'Insurance number',
    company: 'Bolag',
    product: 'Produkt',
    unlist: 'Onoterad',
    stock_list: 'Noterad aktie',
    stock_unlist: 'Onoterad aktie',
    investment_knowledge_test_title: 'Passandeprövning - Onoterade aktier',
    type_s: 'Sälj',
    type_b: 'Köp',
    type_pre: 'Insättning',
    type_presec: 'Premium in-kind',
    presec_sent_to_counterpart: 'Er inflyttsbegäran är skickad till motpart',
    type_wd: 'Uttag',
    "type_premium security": "Inflytt av värdepapper",
    user_documents: 'User documents',
    portfolio_documents: 'Portfolio documents',
    client: 'Client',
    ssn_org_num: 'SSN/Org nr',
    distributor: 'Distributor',
    updated_at: 'Updated at',
    view: 'Se mer',
    a_summary: 'Summary',
    type_wdsec: "Uttag",
    wdsec_title: "Uttagsbegäran mottagen",
    wdsec_text: "Er uttagsbegäran är mottagen av försäkringsbolaget",
    wdsec_title_2: 'Uttag genomfört',
    wdsec_text_2: 'Uttag genomfört.',
    type_surr: 'Avslutsbegäran',
    surr_text: 'Er avslutsbegäran är mottagen',
    surr_text_2: 'Avslut genomförd',
    surr_title: 'Avslut pågår',
    surr_title_2: 'Avslut genomförd',
    type_exch: 'Konvertering',
    buy_sent: 'Er order är skickad.',
    buy_sent_title: 'Order skickad',
    archived_approvals: 'Archive',
    completed_approvals: 'Completed',
    menu_settings: 'Settings',
    menu_home: 'Home',
    menu_users: 'Users',
    menu_approvals: 'Approvals',
    menu_forms: 'Forms',
    menu_securities_cms: 'Securities CMS',
    menu_files: 'Files',
    menu_my_clients: 'My clients',
    creation_date: 'Creation date',
    priority: 'Priority',
    assigned: 'Assigned',
    due_date: 'Due date',
    available_amount: 'Available amount',
    used_amount: 'Used amount',
    transaction_info: 'Order information',
    choose_subaccount: 'Choose subaccount',
    portfolios: 'Portfolios',
    portfolio_information: 'Portfolio information',
    pending_transactions: 'Pending transactions',
    nin: 'National identity number',
    profile_attributes: 'Profile attributes',
    portfolio_attributes: 'Portfolio attributes',
    life_assured: 'Life assured',
    beneficiary: 'Beneficiary',

    standard: 'Standard',
    urgent: 'Urgent',
    waiting_on_third_party: 'Waiting on third party',
    waiting_on_management: 'Waiting on management',
    processing: 'Processing',
    suspected: 'Suspected',
    normal: 'Normal',
    approved: 'Approved',
    denied: 'Denied',
    comment: 'Comment',
    completed: 'Completed',
    in_progress: 'In progress',
    pending: 'Pending',
    uploaded_files: 'Uploaded files',
    leave_your_verdict: 'Leave your verdict',
    due_date_label: 'Due date (optional)',
    priority_label: 'Priority (optional)',
    select_priority: 'Select priority',
    verdict: 'Verdict',
    select_verdict: 'Select verdict',
    new_comment: 'New comment',
    exit: 'Exit',
    archive: 'Archive',
    remove_from_archive: 'Remove from archive',
    verdicts: 'Verdicts',
    be_the_first_to_leave_a_verdict: 'Be the first to leave a verdict',
    back: 'Back',
    aptitude_tests: 'Appropriateness test',
    approval_test_completed: 'Completed',
    approval_test_not_completed: 'Not completed - Go to the test',
    knowledge_test_cryptocurrency: 'Appropriateness test - Cryptocurrency',
    knowledge_test_stock: 'Appropriateness test - Unlisted stock',
    create_approval: 'Create approval',
    create_sof_approval: 'Create Source of funds application approval',
    create_policy_approval: 'Create Policy application approval ',
    search: 'Search',
    person: 'Person',
    choose_type: 'Choose type',
    organization_number: 'Organization number',
    national_identity_number: 'National identity number',
    change_notes: 'Change notes',
    edit_form: 'Edit form',
    save_changes: 'Save changes',
    please_add_note_of_changes: 'Please add a note of the changes you have made',
    pof: 'Proof of funds',
    form_response: 'Form response',
    upload_pof: 'Upload proof of funds',
    upload: 'Upload',
    event: 'Event',
    delete: 'Delete',
    download: 'Download',
    description: 'Description',
    startyear: 'Start year',
    endyear: 'End year',
    deposit_invalid_message: 'You need to select a subaccount and set the issue date to be able to proceed, make sure you save the changes before you proceed',
    confirm_delete_transaction: 'Are you sure you want to delete this transaction? This action cannot be undone.',
    latest_approvals: 'Latest approvals',
    menu_portfolios: 'Portfolios',
    open: 'Open',
    score: 'Score',
    view_history: 'History',
    pep_risk: 'PEP risk',
    tax_country_risk: 'Tax domicile risk',
    nationality_risk: 'Citizenship risk',
    occupation_risk: 'Occupation risk',
    dd_policy_approval: 'Due diligence portfolio',
    dd_due_dilligence_crossover: 'Risk Tool',
    dd_sof: 'Due diligence source of funds',
    create_entry: 'Create Premium in-kind',
    risk_tool: 'Risk tool',
    roles: 'Roles',
    tags: 'Tags',
    empty_input_message: 'Please fill in the empty fields before you proceed',
    choose_source_of_funds_for_entry: 'Choose source of funds for the Premium in-kind',
    error_creating_entry: 'Something went wrong when creating the transaction, please try again',
    entry_completed: 'Premium in-kind completed',
    create_new_entry: 'Create new entry',
    tof_approval: 'Premium in-kind',
    dd_deposit: 'Due diligence deposit',
    p_roaring: 'personal data',
    user_sources_for_tof: 'Used sources for Premium in-kind',
    menu_pending_fa_transactions: 'Premium in-kind',
    pending_tof: 'Pending Premium in-kind',
    pending_tof_desc: 'Here you can see all the pending Premium in-kind that needs to be booked in FA-back. When you have booked the transaction in FA-back you can mark the transaction as completed.',
    choose_status: 'Choose status',
    portfolio: 'Portfolio',
    complete: 'Complete',
    set_to_pending: 'Set to pending',
    are_you_sure_tof_approve: 'Are you sure you want to approve this Premium in-kind? If you change your mind you can always set the transaction back to pending',
    deposit_amount_risk: 'Premium amount',
    archived: 'Archived',
    show_form_files: 'Show documents',
    inv_rep: 'Investment representative',
    form: 'Form',
    choose_form: 'Choose form',
    sync_user: 'Sync user',
    latest_update: 'Latest update',
    your_sources: 'Your sources',
    proven_sof: 'Proven source of funds',
    company_country_founded_risk: 'Country where the company is founded',
    company_country_registered_risk: 'Country where the company is registered',
    manual_update_risk: 'Manual update',
    risk_score: 'Risk score',
    fma_risk: 'Risk tool',
    security_id: 'Security ID',
    security_name: 'Security name',
    old_sof_desc: 'Previous declared sources',
    business_outside_sweden: 'Business outside Sweden',
    waiting_on_payment: 'Waiting on payment',
    choose_units: 'Units',
    choose_origin: 'Current bank/custodian',
    choose_origin_account: 'Current account number ',
    amount_required_err: 'Amount is required',
    sub_account_required_err: 'Choose sub account in order to approve this transaction',
    choose_sub_account: 'Subaccount',
    approve_transaction: 'Approve transaction',
    deny_transaction: 'Deny transaction',
    origin: 'Origin',
    origin_account: 'Origin account',
    units: 'Units',
    edit_amount: 'Edit amount',
    used_sources_for_transaction: 'Used sources for Premium in-kind',
    tof: 'Premium in-kind',
    batch_id: 'Batch ID',
    sub_account: 'Sub account',
    proxy_sent: 'Proxy sent',
    waiting_on_counterpart: 'Waiting on counterparty',
    investment_received: 'Investment received',
    complete_batch: 'Complete batch',
    save_contact_details: 'Save contact details',
    contact_details: 'Contact details',
    choose_paid_premium_transaction: 'Choose paid premium transaction',
    choose_transaction_date: 'Transaction date',
    'ready-for-execution': 'Ready for execution',
    choose_unit_price: 'Unit price',
    choose_paid_premium: 'Choose paid premium transaction',
    transaction_date: 'Transaction date',
    choose_currency: 'Choose currency',
    choose_exchange_rate: 'Exchange rate',
    premium_withdrawal_completed: 'Not completed in FA',
    hubins_status: 'Hubins status',
    quantum_status: 'Quantum status',
    batch_status: 'Batch status',
    transaction_singular: 'Transaktion',
    are_you_sure_you_want_to_complete_the_batch: 'All transactions will be completed and booked in FA-back. Are you sure you want to complete the batch?',
    accrued_interest_label: 'Accrued interest (Write "?" to calculate automatically)',
    exchange_rate: 'Exchange rate',
    portfolio_exact_name: 'Portfolio (Insurance number)',
    start_date: 'Start date',
    only_displays_latest_100_transactions: 'Only displays the latest 100 transactions, if you need to find specific transactions, filter by start date, end date, type eller portfolio number.',
    choose_portfolio: 'Choose portfolio',
    choose_security: 'Choose security',
    paid_premium_type: 'Paid premium type',
    choose_paid_premium_type: 'Choose paid premium type',
    paid_premium_type_required: 'You need to choose a paid premium type',
    portfolio_required: 'You need to choose a portfolio',
    security_required: 'You need to choose a security',
    paid_premium_created: 'Paid premium created',
    trade_order: 'Trade order',
    reset_filters: 'Reset filters',
    save_transfer_instructions: 'Save transfer instructions',
    transfer_instructions: 'Transfer instructions',
    booking_status: 'Booking status',
    reserved_by_transaction: 'Reserved',
    security_booked: 'Security booked',
    files: 'Files',
    draft_transactions: 'Draft',
    create_new_tof: 'Create new Premium in-kind',
    choose_sources: 'Choose sources',
    amount_is_required: 'Amount is required to create the transaction',
    security_is_required: 'Security is required to create the transaction',
    clients: 'Clients',
    orders: 'Orders',
    pending_fa_booking: 'Pending FA booking',
    pending_fa_tax_booking: 'Pending FA tax booking',
    approval_status: 'Approval status',
    fetch_exchange_rate: 'Fetch exchange rate',
    failed_to_fetch_exchange_rate: 'Failed to fetch exchange rate',
    fetch_unit_price: 'Fetch unit price',
    failed_to_fetch_unit_price: 'Failed to fetch unit price',
    create_single_transaction_execution: 'Create order process',
    create_batch: 'Create order process',
    add_to_batch: 'Add to batch',
    trade_amount_in_portfolio_currency: 'Trade amount in portfolio currency',
    cash_flow: 'Cash flow',
    under_review: 'Under review',
    no_policy_holder_found: 'No policy holder found on the selected portfolio',
    are_you_sure_you_want_to_delete_this_batch: 'Are you sure you want to delete this batch?',
    contact_name: 'Contact person',
    contact_email: 'Contact person\'s email',
    contact_phone: 'Contact person\'s phone number',
    deny_batch: 'Deny batch',
    batch_is_not_ready_for_execution: 'All transactions must be ready for execution before the batch can be completed',
    initial_trade_amount: 'Initial investment amount',
    trade_origin: 'Current bank/custodian',
    trade_origin_account: 'Current account number',
    policy_approval: 'Policy',
    order_processes: 'Order processes',
    all_orders: 'All orders',
    fa_transactions: 'FA transactions',
    cash_balance: 'Unbooked deposits',
    received_premium_in_kind: 'Unbooked premium in kind',
    create_premium_in_kind: 'Create Premium in-kind',
    create_paid_premium: 'Create paid premium',
    unprocessed_orders: 'Unprocessed orders',
    order_type: 'Order type',
    feed: 'Fee receivable',
    'fee receivable': 'Fee receivable',
    manage_sof: 'Manage sources',
    manage_sof_description: 'Please note that this view does not take into account deposits with the status "Draft".',
    on_hold: 'On hold',
    waiting_on_client: 'Waiting on client',
    denied_by_ql: 'Denied by QL',
    waiting_on_tof_approval: 'Waiting on premium in-kind approvals',
    waiting_on_ql_signature: 'Waiting for QL’s signature',
    signed_by_ql: 'Signed by QL',
    sent_to_counterparty: 'Sent to counterparty',
    add_settlement_instructions: 'Add settlement instructions in bank',
    waiting_on_settlement: 'Waiting on settlement',
    investment_received_ownership_confirmed: 'Investment received / Ownership verified',
    card_on_hold: 'In progress',
    card_waiting_on_client: 'Waiting on client',
    card_denied_by_ql: 'In progress',
    card_waiting_on_tof_approval: 'In progress',
    card_waiting_on_ql_signature: 'In progress',
    card_signed_by_ql: 'In progress',
    card_sent_to_counterparty: 'Sent to counterparty',
    card_add_settlement_instructions: 'Waiting on counterparty',
    card_waiting_on_settlement: 'Waiting on counterparty',
    card_investment_received_ownership_confirmed: 'Waiting for booking',
    card_approved: 'Investment received',
    are_you_sure_you_want_to_delete_this_transaction: 'Are you sure you want to delete this transaction?',
    end_date: 'End date',
    create_order: 'Create order',
    max_value_exceeded: 'Please enter a lower amount',
    updated_by: 'Updated by',
    client_view: 'Client view',
    business_profile: 'Business profile',
    risk_profile: 'Risk profile',
    due_dilligence_checks: 'Due diligence checks',
    fa_notes: 'FA notes',
    internal_documents: 'Internal documents',
    imported_risk_categorization_from_fa: 'Imported Risk Categorization from FA',
    comments: 'Comments',
    add_comment: 'Comment',
    reply: 'Reply',
    replying_to: 'Replying to',
    broker: 'Broker',
    view_rights: 'View rights',
    are_you_sure_you_want_to_delete_this_comment: 'Are you sure you want to delete this comment?',
    unhandled_orders: 'Unhandled orders',
    pending_batches: 'Pending order processes',
    all_batches: 'All order processes',
    registration_completed: 'Registration completed',
    registration_pending: 'Registration pending',
    inactive: 'Inactive',
    portfolio_cancelled: 'Cancelled',
    tof_not_ready_for_execution: 'Premium in-kind is not set to ready for execution',
    policy_holder_comments: 'Policyholder comments',
    portfolio_comments: 'Portfolio comments',
    waiting_on_quantum: 'Waiting on Quantum',
    stage: 'Stage',
    stage_label: 'Stage (optional)',
    await_third_party: 'Waiting on third party',
    follow_up: 'Follow up',
    to_be_approved: 'To be approved',
    started: 'Started',
    awaits_approval: 'Awaits approval',
    paused: 'Paused',
    resume: 'Resume',
    menu_risk_assessment_params: 'Risk assessment params',
    confirm_delete: 'Are you sure you want to delete this?',
    first_deposit_amount: 'First premium deposit amount',
    units_is_required: 'Units are required to create the transaction',
    unit_price_is_required: 'Unit price is required to create the transaction',
    exchange_rate_is_required: 'Exchange rate is required to create the transaction',
    choose_source: 'Choose source',
    reports: 'Reports',
    report_generator: 'Report',
    postal_code: 'Postal code',
    phone_number: 'Phone number',
    address: 'Address',
    city: 'City',
    edit_portfolio: 'Edit portfolio',
    edit_profile: 'Edit profile',
    are_you_sure_you_want_to_delete_file: 'Are you sure you want to delete this file? This action cannot be undone.',
    delete_template_confirmation: 'Are you sure you want to delete this template? All the outputs that have been generated from this template will also be deleted.',
    delete_output_confirmation: 'Are you sure you want to delete this output?',
    enddate: 'End date',
    startdate: 'Start date',
    params: 'Parameters',
    created_by: 'Created by',
    report_create_template: 'Create report template',
    report_edit_template: 'Edit report template',
    report_preview_status_completed: 'Completed',
    report_preview_status_pending: 'Pending',
    report_preview_status_failed: 'Failed',
    issued_at: 'Issued at',
    terminated_at: 'Terminated at',
    termination_type: 'Termination type',
    tin_number: 'TIN number',
    tax_domiciles: 'Tax domicile',
    profile: 'Profile',
    phone: 'Phone number',
    fee_buffert: 'Fee buffer',
    future_investments: 'Future investments',
    choose_purpose: 'Choose purpose',
    transaction_type_tof: 'Premium in-kind',
    purpose_of_transaction: 'Purpose of transaction',
    report_template_type_portfolio: 'Portfolio',
    report_template_type_profile: 'Profile',
    report_template_type_portfolio_roles: 'Portfolio Roles',
    report_template_type_security_positions: 'Security Positions',
    menu_platform_alerts: 'Notifications',
  }
});

export default keys;
