import React from 'react';
import __reg from 'localisation/hubins/registration';
import __ from 'localisation';
import { formatMoney } from 'helpers';
import { __route } from 'localisation';
import Heading from '@hubins/components/Heading';
import Button, { ButtonWrap } from '@hubins/components/Button';
import LoadingBar from '@hubins/components/LoadingIndicators';

export const portfolioStatusesOptions = [
  { title: 'Issued', id: 'Issued' },
  { title: 'Pending', id: 'Pending' },
  { title: 'Inactive', id: 'Inactive' },
  { title: 'Cancelled', id: 'Cancelled' },
  { title: 'Closed Death Case', id: 'Closed Death Case' },
  { title: 'Registration Complete', id: 'Registration Complete' },
  { title: 'Surrender', id: 'Surrender' },
  { title: 'Terminated', id: 'Terminated' },
  { title: 'Void', id: 'Void' },
];

export const getLifeAssured = (userInfo, user = {}) => {

  const isCompany = user
    && user.active_profile
    && user.active_profile.fa_connection
    && user.active_profile.fa_connection.contact_juridical === 'CO';
  const lifeAssuredFromPortfolio = [1, 2, 3].reduce((assured, index) => {
    const key = `portfolio.policy.life_assured${index}`;
    if (userInfo[`${key}_ssn`]) {
      assured.push({
        ssn: userInfo[`${key}_ssn`],
        name: userInfo[`${key}_name`],
      });
    }
    return assured;
  }, []);

  if (lifeAssuredFromPortfolio.length > 0) {
    return lifeAssuredFromPortfolio;
  }

  if (isCompany) {
    return [
      {
        ssn: '',
        name: 'Samtliga firmatecknare',
      }
    ];
  }
  return [
    {
      ssn: user.active_profile ? user.active_profile.national_identity_number : '',
      name: user.name
    }
  ];
};

export const getPolicyHolders = (userInfo, user = {}) => {
  const policyHolderFromPortfolio = [1, 2].reduce((policyHolders, index) => {
    const key = `portfolio.policy.policyholder${index}`;
    if (userInfo[`${key}_ssn`]) {
      policyHolders.push({
        ssn: userInfo[`${key}_ssn`],
        name: userInfo[`${key}_name`]
      });
    }
    return policyHolders;
  }, []);

  if (policyHolderFromPortfolio.length > 0) return policyHolderFromPortfolio;

  return [
    {
      ssn: user.active_profile ? user.active_profile.contact_ssn : '',
      name: user.active_profile ? user.active_profile.name : '',
    }
  ];
};

export const getBeneficiaries = (userInfo) => {
  const beneficiariesFromPortfolio = [1, 2, 3, 4, 5, 6].reduce((beneficiaries, index) => {
    const key = `portfolio.policy.beneficiaries${index}`;
    if (userInfo[`${key}_ssn`]) {
      beneficiaries.push({
        ssn: userInfo[`${key}_ssn`],
        name: userInfo[`${key}_name`]
      });
    }
    return beneficiaries;
  }, []);

  if (beneficiariesFromPortfolio.length > 0) return beneficiariesFromPortfolio;

  return [
    {
      ssn: __reg('according_to_beneficiaries'),
      name: __reg('according_to_beneficiaries'),
    }
  ];

};

export const ALERT_TAGS = ['terms_needed'];

export const getPortfolioAlert = (tag, portfolio, closePortfolioNotification, setShowTermsModal) => {
  switch (tag) {
    case 'terms_needed':
      return (
        <>
          <Heading size="3">Du har en pågående uppdatering</Heading>
          <p>
            Ni har påbörjat en uppdatering av era inlämnade svar och vi ber er vänligen gå igenom samtliga uppgifter och göra eventuella ändringar innan ni skickar in uppdateringen.
          </p>
          <ButtonWrap className='s-top-l'>
            <Button icon="arrow right 1" iconColor='white' className="cta" onClick={(e) => { closePortfolioNotification(e); setShowTermsModal(true); }} >
              Skicka in uppdatering
            </Button>
            <Button className='white secondary-alert-btn' icon="arrow right 1" onClick={closePortfolioNotification}>
              Gör senare
            </Button>
            <Button className='secondary secondary-modal-btn' icon="arrow right 1" href={__route('settings')}>
              Fortsätt uppdatera
            </Button>
          </ButtonWrap>
        </>
      );
    default:
      return null;
  }
};

const loadingObj = {
  short_name: <LoadingBar medium />,
  policy_holder: [],
  available_balance: 0,
  updated_at: <LoadingBar medium />,
};

export const loadingState = [
  loadingObj,
  loadingObj,
  loadingObj,
  loadingObj,
  loadingObj,
  loadingObj,
  loadingObj,
  loadingObj,
  loadingObj,
];

export const defaultHeaders = [
  {
    Header: __('portfolio'),
    accessor: 'short_name'
  },
  {
    Header: __('policy_holder'),
    accessor: 'policy_holder.name',
    Cell: ({ row }) => row?.original?.policy_holder?.map((profile) => profile.name).join(', ') || ''
  },
  {
    Header: __('account_balance'),
    accessor: 'available_balance',
    Cell: ({ row }) => formatMoney(row.original.available_balance, row.original.currency)
  },
  {
    Header: __('updated_at'),
    accessor: 'updated_at'
  }
];
