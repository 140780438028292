import React from "react";
import { motion } from "framer-motion";

const ExpandedRow = ({ row, component, colSpan, isExpanded }) => {
  return (
    <motion.tr
      initial={false}
      animate={{
        height: isExpanded ? "auto" : 0,
        transition: { duration: 0.25 },
      }}
      style={{ display: "flex", overflow: "hidden" }}
    >
      <td colSpan={colSpan} style={{ width: "100%" }}>
        {component(row)}
      </td>
    </motion.tr>
  );
};

export default ExpandedRow;
