import React, { useState } from 'react';
import { Column, Columns } from 'components/Columns';
import Heading from '@hubins/components/Heading';
import __ from 'localisation';
import SimpleTable from 'modules/Tables/SimpleTable';
import LoadingBar from '@hubins/components/LoadingIndicators';
import { getMoney } from 'helpers';
import Comments from '@hubins/components/Comments';
import EditProfileModal from '@hubins/components/EditProfileModal';
import Button, { ButtonWrap } from '@hubins/components/Button';
import EditPortfolioModal from '@hubins/components/EditPortfolioModal';
import TaxDomiciles from '@hubins/components/TaxDomiciles';

const typeValueHeaders = [
  {
    Header: '',
    accessor: 'type',
  },
  {
    Header: '',
    accessor: 'value',
  }
]

const Dashboard = ({ client: propsClient, portfolio: propsPortfolio, backOfficeUser }) => {

  const [openProfileModal, setOpenProfileModal] = useState(false);
  const [openPortfolioModal, setOpenPortfolioModal] = useState(false);
  const [client, setClient] = useState(propsClient);
  const [portfolio, setPortfolio] = useState(propsPortfolio);

  const clientData = [
    {
      type: __('name'),
      value: client?.name || <LoadingBar />,
    },
    {
      type: __('national_identity_number'),
      value: client?.national_identity_number || <LoadingBar />,
    },
    {
      type: __('email'),
      value: client?.email || '',
    },
    {
      type: __('phone_number'),
      value: client?.phone_number || '',
    },
    {
      type: __('country'),
      value: client?.country || '',
    },
    {
      type: __('city'),
      value: client?.city || '',
    },
    {
      type: __('postal_code'),
      value: client?.postal_code || '',
    },
    {
      type: __('address'),
      value: client?.address || '',
    },
    {
      type: __('updated_at'),
      value: client?.updated_at || <LoadingBar />,
    }
  ];

  const portfolioData = [
    {
      type: __('insurance_number'),
      value: portfolio?.short_name || <LoadingBar />,
    },
    {
      type: __('distributor'),
      value: portfolio?.distributor || <LoadingBar />,
    },
    {
      type: __('status'),
      value: portfolio?.status || <LoadingBar />,
    },
    {
      type: __('account_balance'),
      value: getMoney(portfolio?.account_balance || 0),
    },
    {
      type: __('market_value'),
      value: getMoney(portfolio?.market_value || 0),
    },
    {
      type: __('updated_at'),
      value: portfolio?.updated_at || <LoadingBar />,
    },
    {
      type: __('issued_at'),
      value: portfolio?.issue_date || '',
    },
    {
      type: __('terminated_at'),
      value: portfolio?.terminated_at || '',
    },
    {
      type: __('termination_type'),
      value: portfolio?.termination_type || '',
    },
  ];

  return (
    <Columns>
      <Column l="6">
        <Heading size="4" className='s-bottom-xl'>{__('policy_holder')}</Heading>
        <SimpleTable headers={typeValueHeaders} data={clientData} />
        {
          backOfficeUser && (
            <>
              <EditProfileModal
                profile={client}
                open={openProfileModal}
                setOpen={setOpenProfileModal}
                setProfile={setClient}
              />
              <ButtonWrap>
                <Button type="button" onClick={() => setOpenProfileModal(true)}>
                  {__('edit')}
                </Button>
              </ButtonWrap>
            </>
          )
        }
        <Heading size="5" className='s-bottom-m s-top-l'>{__('tax_domiciles')}</Heading>
        <TaxDomiciles profileUuid={client?.uuid} />
      </Column>
      <Column l="6">
        <Heading size="4" className='s-bottom-xl'>{__('portfolio')}</Heading>
        <SimpleTable headers={typeValueHeaders} data={portfolioData} />
        {
          backOfficeUser && (
            <>
              <EditPortfolioModal
                portfolio={portfolio}
                open={openPortfolioModal}
                setOpen={setOpenPortfolioModal}
                setPortfolio={setPortfolio}
              />
              <ButtonWrap>
                <Button type="button" onClick={() => setOpenPortfolioModal(true)}>
                  {__('edit')}
                </Button>
              </ButtonWrap>
            </>
          )
        }
      </Column>
      <Column l="6">
        <Comments
          title={__('policy_holder_comments')}
          model='Profile'
          modelUuid={client?.uuid}
          presetChannels={['hubins_backoffice', 'hubins_compliance', 'quantum_backoffice', 'quantum_compliance']}
        />
      </Column>
      <Column l="6">
        <Comments
          title={__('portfolio_comments')}
          model='Portfolio'
          modelUuid={portfolio?.uuid}
          presetChannels={['hubins_backoffice', 'hubins_compliance', 'quantum_backoffice', 'quantum_compliance']}
        />
      </Column>
    </Columns>

  )
};

export default Dashboard;