// always write a / before the url for regular routes
// if you need to use the variable/props function,
// note that it's not possible to switch between languages
// and automatically change to the new translated url.
// So then, make multiple routes instead, like __route('case_start') + id + __route('case_end')

// To translator: No need to translate variables that starts with a :. Ex objectid in '/case/:objectid'

export const routes = () => ({

  root: '/', // tidigare /investeringskonton

  testroute: `/test/:id`,
  testStart: '/test/',
  testEnd: '/end',

  landing: 'https://hubins.com',
  clear: '/clear',
  login: '/login',
  logout: '/logout',
  loginSelect: '/login/select',
  signup: '/signup',
  signupComp: '/signup/:org',
  landingPage: '/landing-page',
  about: '/about',
  contact: '/contact',
  legal: '/legal',
  cookies: '/cookies',
  privacy: '/privacy',
  profile: '/profile',

  myAccountsOverview: 'overview', // for hash url, no slash
  myAccountsHolding: 'holdings', // for hash url, no slash
  myAccountsTransactions: 'transactions', // for hash url, no slash
  myAccountsReport: 'report', // for hash url, no slash
  myAccountsPolicy: 'policy',

  invest: '/invest',
  case: '/case', // used as /case/1234
  case_objectid: '/case/:objectid',
  case_objectid_blocksale: '/case/blocksale',
  subscription: '/subscription', // used as /subscription/1234
  subscription_fund_objectid: '/subscription/:fund/:objectid',
  subscription_objectid: '/subscription/:objectid',
  blocksale: '/subscription-blocksale',

  deposit: '/deposit',
  depositSuccessAg: '/deposit/success-autogiro',
  depositInstructions: '/deposit/instructions', // also used as /deposit/instructions/q/:account/:orderid/
  withdrawal: '/withdrawal',
  withdrawalSuccess: '/withdrawal/success',

  settings: '/settings',
  savedDocuments: 'stored-documents', // for hash url, no slash
  settingsProfile: 'profile', // for hash url, no slash
  settingsKyc: 'kyc', // for hash url, no slash
  settingsPayment: 'payment-options',  // for hash url, no slash

  registerautogiro: '/register-autogiro',
  messages: '/messages',

  marketBuy: '/market/buy',
  marketBuy_id: '/market/buy/:id',
  marketBuySuccess: '/market/buy-success/',
  subscriptionBuySuccess: '/primary-market/success',

  marketSell: '/market/sell',
  marketSell_id: '/market/sell/:id',
  marketSellSuccess: '/market/sell-success/',

  companySignatory: '/company/signatory',
  companySignatorySignup1: '/company/signatory/signup/1',
  companySignatorySignup2: '/company/signatory/signup/2',
  companySignatorySignupSuccess: '/company/signatory/success',

  companyPrincipal: '/company/principal',
  companyPrincipalSignup1: '/company/principal/signup/1',
  companyPrincipalSignup2: '/company/principal/signup/2',
  companyPrincipalSignupSuccess: '/company/principal/success',



  companySignupBasicInfo: '/company/signup/basic-info',
  companySignupSuccess: '/company/signup/success',

  signupSwitch: '/signup-account',
  signupPersonnel: '/registration/private',
  signup1: '/signup/1',
  p_da: '/signup/20',
  p_da_so: '/signup/demand-assessment',
  demandassessment_signoff: '/registrering/da/signoff',
  companyHubinsSummary: '/company/hubins-summary',
  companyHubinsSignoff: '/company/hubins-signoff',
  yearlyReminder: '/yearly-reminder',
  aftersale: '/aftersale',
  companyAftersale: '/aftersale',

  tests: '/tests',
  tests0: '/tests/choose',
  tests1: '/knowledge-test',
  p_kt: '/demand-assessment/knowledge-test',
  c_kt: '/company/demand-assessment/knowledge-test',
  knowledgeTest: '/tests/knowledge',
  p_kt_start: '/tests/knowledge-start',
  companyKnowledgeTestStart: '/company/tests/knowledge-start',
  knowledgeTestResult: '/tests/knowledge/result',
  companyKnowledgeTestResult: '/company/knowledge/result',
  knowledgeTestResult_correct_answer: '/tests/knowledge/result',
  tests2: '/appropriateness-test',
  tests3: '/financial-situation',

  c_da: '/company/demand-assesment/1', // Knowledge Assessment - previous companySignup20
  c_da_so: '/company/demand-assessment/2', // motsvarande privat signup22 tidigare companySignup22
  companyTests1: '/company/knowledge-test',
  companyTests2: '/company/appropriateness-test',
  companyTests3: '/company/financial-situation',


  terms: '/terms',
  c_terms: '/company/terms',

  brokeradvice: '/broker-advice',

  cms: '/cms',
  cmsEdit: '/cms/edit',
  cmsEdit_objectid_language: '/cms/edit/:objectid/:language',
  cmsEdit_objectid: '/cms/edit/:objectid/',

  // Hubins specifik routes
  p_ins: '/insurance-information',
  c_ins: '/company/insurance-information',
  kyc: '/know-your-client',
  ckyc: '/company/know-your-client',
  pkyc: '/company/know-your-client/beneficial',
  signup2: '/know-your-client',
  sof: '/source-of-funds',
  companySof: '/company/source-of-funds',
  sofdoc: '/proof-of-funds',
  companySofdoc: '/company/proof-of-funds',
  sofTriggerSuccess: '/deposit/done',

  welcome: '/välkommen',

  portfolio_registration_summary_private: '/registration/summary/private',
  portfolio_registration_summary_company: '/registration/summary/company',
});

export default routes;

/**
 * Usage:
import { __route } from 'localisation';
 * replace href="/login" to href={__route('login')}
 */
