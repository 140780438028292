import React, { useState } from 'react';
import Modal from '@hubins/components/Modal/Modal';
import Heading from '@hubins/components/Heading/Heading';
import Input from '@hubins/components/Input';
import { Column, Columns } from 'components/Columns';
import __ from 'localisation';
import Button, { ButtonWrap } from '@hubins/components/Button';
import middleman from '@hubins/middleman';
import Dropdown from '@hubins/components/Dropdown';
import ErrorMessage from 'components/ErrorMessage';
import { portfolioStatusesOptions } from 'helpers/portfolio';
import DateInput from '@hubins/components/DateInput';

const EditPortfolioModal = ({ portfolio, open, setOpen, setPortfolio }) => {

  const [distributor, setDistributor] = useState(portfolio?.distributor || '');
  const [status, setStatus] = useState(portfolio?.status || '');
  const [issueDate, setIssueDate] = useState(portfolio?.issue_date || '');
  const [terminatedAt, setTerminatedAt] = useState(portfolio?.terminated_at && portfolio?.terminated_at?.substring(0, 10) || '');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await middleman.post(`/ql/crm/portfolio/update/${portfolio.uuid}`, {
        distributor,
        status,
        issueDate,
        terminatedAt,
      });
      setPortfolio((prevPortfolio) => ({
        ...prevPortfolio,
        distributor,
        status,
        issue_date: issueDate,
        terminated_at: terminatedAt,
      }));
      setOpen(false);
    } catch (e) {
      setError(e.message);
    }
    setLoading(false);
  }

  return (
    <Modal open={open} callback={setOpen}>
      <form onSubmit={handleSubmit}>
        <Columns>
          <Column s="12">
            <Heading size="4">{__('edit_portfolio')}</Heading>
          </Column>
          <Column m="6">
            <Input label={__('distributor')} value={distributor} callback={setDistributor} />
          </Column>
          <Column m="6">
            <Dropdown label={__('status')} options={portfolioStatusesOptions} value={status} callback={setStatus} />
          </Column>
          <Column m="6">
            <DateInput label={__('issued_at')} value={issueDate} callback={setIssueDate} />
          </Column>
          <Column m="6">
            <DateInput label={__('terminated_at')} value={terminatedAt} callback={setTerminatedAt} />
          </Column>
          <Column s="12">
            <ButtonWrap>
              <Button type="submit" className='cta' disabled={loading} loading={loading}>
                {__('save')}
              </Button>
              <Button type="button" onClick={() => setOpen(false)}>
                {__('cancel')}
              </Button>
            </ButtonWrap>
            <ErrorMessage text={error} showError={!!error} />
          </Column>
        </Columns>
      </form>
    </Modal>
  )

};

export default EditPortfolioModal;