import React from 'react';
import Icon from '@hubins/components/IconNew';
import './index.scss';

const SearchInput = ({ onChange, value, className = '', placeholder, loading }) => {

  return (
    <div className={"input__wrap input__wrap--inverted searchinput " + className} >
      <input className="input neutral-2" onChange={onChange} value={value} type="search" name="search" placeholder={placeholder || "Type to search"} />
      <div className="input__icon neutral-2">
        {loading ? (
          <div style={{ transform: "translateY(50%)" }}>
            <Icon icon="loading" anim="spin" size={16} color={'primary'} />
          </div>
        ) : (
          <Icon
            icon="search"
            size={16} color="primary"
          />
        )}

      </div>
      {/* {(
        <div className="input__suffix neutral-3">Sök</div>
      )} */}
    </div>
  );
};

export default SearchInput;
