import React, { useState } from 'react';
import Modal from '@hubins/components/Modal/Modal';
import Heading from '@hubins/components/Heading/Heading';
import Input from '@hubins/components/Input';
import { Column, Columns } from 'components/Columns';
import __ from 'localisation';
import Button, { ButtonWrap } from '@hubins/components/Button';
import middleman from '@hubins/middleman';
import ErrorMessage from 'components/ErrorMessage';

const EditProfileModal = ({ profile, open, setOpen, setProfile }) => {

  const [address, setAddress] = useState(profile?.address || '');
  const [city, setCity] = useState(profile?.city || '');
  const [postalCode, setPostalCode] = useState(profile?.postal_code || '');
  const [country, setCountry] = useState(profile?.country || '');
  const [phoneNumber, setPhoneNumber] = useState(profile?.phone_number || '');
  const [email, setEmail] = useState(profile?.email || '');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await middleman.post(`/ql/crm/profile/update/${profile.uuid}`, {
        address,
        city,
        postal_code: postalCode,
        country,
        phone_number: phoneNumber,
        email,
      });
      setOpen(false);
      setProfile((prevProfile) => ({
        ...prevProfile,
        address,
        city,
        postal_code: postalCode,
        country,
        phone_number: phoneNumber,
        email,
      }));
    } catch (error) {
      setError(error.message);
    }
    setLoading(false);
  }

  return (
    <Modal open={open} callback={setOpen}>
      <form onSubmit={handleSubmit}>
        <Columns>
          <Column s="12">
            <Heading size="4">{__('edit_profile')}</Heading>
          </Column>
          <Column m="6">
            <Input label={__('address')} value={address} callback={setAddress} />
          </Column>
          <Column m="6">
            <Input label={__('city')} value={city} callback={setCity} />
          </Column>
          <Column m="6">
            <Input label={__('postal_code')} value={postalCode} callback={setPostalCode} />
          </Column>
          <Column m="6">
            <Input label={__('country')} value={country} callback={setCountry} />
          </Column>
          <Column m="6">
            <Input label={__('phone_number')} value={phoneNumber} callback={setPhoneNumber} />
          </Column>
          <Column m="6">
            <Input label={__('email')} value={email} callback={setEmail} />
          </Column>
          <Column s="12">
            <ButtonWrap>
              <Button type="submit" className='cta' disabled={loading} loading={loading}>
                {__('save')}
              </Button>
              <Button type="button"  onClick={() => setOpen(false)}>
                {__('cancel')}
              </Button>
            </ButtonWrap>
            <ErrorMessage text={error} showError={!!error} />
          </Column>
        </Columns>
      </form>
    </Modal>
  );
};

export default EditProfileModal;
