import React from 'react';
import Icon from '@hubins/components/IconNew';
import __ from 'localisation';

const getRouteForRole = (role, mobile) => {
  switch (role) {
    case 'general':
      return [
        {
          title: __('menu_settings'),
          route: '/dashboard/settings',
          iconName: 'Cog',
          icon: <Icon icon="Cog" />
        }
      ];
    case 'backoffice':
      return [
        {
          title: __('menu_home'),
          route: '/dashboard',
          iconName: 'house',
          icon: <Icon icon="house"  />,
        },
        {
          title: __('menu_portfolios'),
          route: '/dashboard/clients',
          iconName: 'user',
          icon: mobile
            ? <Icon icon="user"   />
            : <Icon anim="" ariaHidden={undefined} className=""  fallback="fallback" icon="users"  spacing="" />,
        },
        {
          title: __('menu_users'),
          route: '/dashboard/profiles',
          iconName: 'user',
          icon: mobile
            ? <Icon icon="user"   />
            : <Icon anim="" ariaHidden={undefined} className=""  fallback="fallback" icon="users"  spacing="" />,
        },
        {
          title: __('menu_approvals'),
          route: '/dashboard/approvals',
          iconName: 'Common File Text by Streamlinehq',
          icon: mobile
            ? <Icon icon="Common File Text by Streamlinehq"   />
            : <Icon anim="" ariaHidden={undefined} className=""  fallback="fallback" icon="test"  spacing="" />,
        },
        {
          title: __('Orders'),
          route: '/dashboard/orders',
          iconName: 'Common File Text by Streamlinehq',
          icon: <Icon icon="Common File Text by Streamlinehq"   />,
        }
      ];
    case 'hubins_backoffice':
    case 'hubins_compliance':
      return [
        {
          title: __('menu_securities_cms'),
          route: `/dashboard/cms`,
          iconName: 'Common File Text by Streamlinehq',
          icon: <Icon icon="Common File Text by Streamlinehq" />,
        },
      ];
    case 'admin':
      return [
        {
          title: __('menu_forms'),
          route: `/formscms`,
          iconName: 'Common File Text by Streamlinehq',
          icon: <Icon icon="Common File Text by Streamlinehq"  />,
          external: true,
        },
        {
          title: __('menu_files'),
          route: `/dashboard/files`,
          iconName: 'Common File Text by Streamlinehq',
          icon: <Icon icon="Common File Text by Streamlinehq"  />,
        },
        {
          title: __('menu_risk_assessment_params'),
          route: '/dashboard/risk-assessment-params',
          iconName: 'Common File Text by Streamlinehq',
          icon: <Icon icon="Common File Text by Streamlinehq" />,
        },
        {
          title: __('report_generator'),
          route: '/dashboard/report-generator',
          iconName: 'Common File Text by Streamlinehq',
          icon: <Icon icon="Common File Text by Streamlinehq" />,
        },
        {
          title: __('menu_platform_alerts'),
          route: '/dashboard/alerts',
          iconName: 'Common File Text by Streamlinehq',
          icon: <Icon icon="Common File Text by Streamlinehq" />,
        },
        // {
        //   title: __('menu_pending_fa_transactions'),
        //   route: '/dashboard/pending-fa-transactions',
        //   iconName: 'Common File Text by Streamlinehq',
        //   icon: <Icon icon="Common File Text by Streamlinehq" />,
        // },
      ];
    case 'broker':
      return [
        {
          title: __('menu_my_clients'),
          route: '/dashboard/clients',
          iconName: 'user',
          icon: mobile
            ? <Icon icon="user"  />
            : <Icon anim="" ariaHidden={undefined} className=""  fallback="fallback" icon="users"  spacing="" />,
        },
      ];
  }
};

export const getAvailableRoutes = (userRoles, mobile) => {

  if (!userRoles || userRoles.length < 1) {
    return [];
  }

  const orderedRoles = [
    'backoffice',
    'admin',
    'broker',
    'general',
    'hubins_backoffice',
    'hubins_compliance',
  ];

  const routes = orderedRoles.reduce((foundRoutes, role) => {

    if (role === 'backoffice') {
      const needles = [
        'hubins_backoffice',
        'hubins_compliance',
        'quantum_backoffice',
        'quantum_compliance',
      ];
      const hasBackOfficeRole = needles.some(needle => userRoles.includes(needle));

      if (hasBackOfficeRole) {
        foundRoutes = [...foundRoutes, ...getRouteForRole(role, mobile)];
      }
    } else if (userRoles.includes(role) || role === 'general') {
      foundRoutes = [...foundRoutes, ...getRouteForRole(role, mobile)];
    }

    return foundRoutes;

  }, []);

  return routes;
};

export default getAvailableRoutes;
