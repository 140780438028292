import React, { useState, useEffect } from 'react';
import middleman from 'helpers/middleman';
import { LoadingBox } from '@hubins/components/LoadingIndicators';
import { loadForm } from 'helpers/form';
import FormsSummary from 'modules/FormsSummary/Summary';
import SimpleTable from 'modules/Tables/SimpleTable';
import { getSummary } from 'helpers/form';
import Heading from '@hubins/components/Heading';
import ApprovalProfilePortfolioSummary from '@hubins/components/ApprovalProfilePortfolioSummary';
import ApprovalTransactionsTable from '@hubins/components/ApprovalTransactionsTable/ApprovalTransactionsTable';
import ApprovalPaidPremiumTable from '@hubins/components/ApprovalPaidPremiumTable';
import ApprovalChooseSubAccount from '@hubins/components/ApprovalChooseSubAccount';
import ApprovalEditTransactionSources from '@hubins/components/ApprovalEditTransactionSources';
import DateInput from '@hubins/components/DateInput';
import Button from '@hubins/components/Button';
import ErrorMessage from 'components/ErrorMessage';
import ErrorBoundary from 'components/ErrorBoundary';
import ClientDocumentsAccordeon from '@hubins/components/ClientDocumentsAccordeon';
import { useTransactions } from 'queries/report';
import TransactionsTable from 'modules/Tables/TransactionsTable';
import ReadMore from '@hubins/components/Accordeon/ReadMore';
import CompanyPersonnelPowersOfAttorney from '@hubins/components/CompanyPersonnelPowersOfAttorney';
import { getTransactionSourceOfFundsHeaders, getDefaultTransactionSourceOfFundsHeaders, getTransactionSourceOfWealthHeaders } from 'helpers/sof';
import FMATable from '@hubins/components/FMATable/FMATable';
import HubinsRisks from 'views/Client/RiskTool/HubinsRisks';
import ExpandableButton from '@hubins/components/ExpandableButton';

import __ from 'localisation';

const Deposit = ({ transaction_uuid, canEdit, isExecuted, setInvalid, endDate }) => {
  const [loading, setLoading] = useState(true);
  const [sources, setSources] = useState([]);
  const [transaction, setTransaction] = useState(false);
  const [form, setForm] = useState(false);
  const [purposeSummary, setPurposeSummary] = useState(false);
  const [portfolio, setPortfolio] = useState(false);
  const [policyHolder, setPolicyHolder] = useState(false);
  const [signatories, setSignatories] = useState([]);
  const [beneficialOwners, setBeneficialOwners] = useState([]);
  const [issueDate, setIssueDate] = useState(false);
  const [risk, setRisk] = useState(false);
  const [sourceOfWealth, setSourceOfWealth] = useState(false);
  const [selectedSubAccount, setSelectedSubAccount] = useState(false);
  const [transactionLoading, setTransactionLoading] = useState(false);
  const [transactionError, setTransactionError] = useState(false);
  const [transactionInfoCompleted, setTransactionInfoCompleted] = useState(false);

  const {
    data: faTransactions,
    isLoading:
    faTransactionsLoading,
    isFetched: faTransactionsFetched
  } = useTransactions(
    '2010-01-01',
    endDate ? endDate : new Date().toISOString().slice(0, 10),
    portfolio
      ? portfolio.uuid : null
  );

  useEffect(() => {
    setInvalid(!transactionInfoCompleted, 'deposit', __('deposit_invalid_message'));
  }, [transactionInfoCompleted]);

  const sourceHeaders = getTransactionSourceOfFundsHeaders(sources);


  const submit = async (e) => {
    e.preventDefault();
    setTransactionError(false);

    if (!issueDate) {
      setTransactionError('Issue date is required');
      return;
    }
    if (!selectedSubAccount) {
      setTransactionError('Subaccount is required');
      return;
    }

    try {
      setTransactionLoading(true);
      await middleman.post(`/ql/crm/transaction/update/${transaction_uuid}`, {
        subPortfolioUuid: selectedSubAccount,
        transactionDate: issueDate
      });

      setTransactionLoading(false);
      setTransactionInfoCompleted(true);
    } catch (err) {
      console.error(err);
      setTransactionLoading(false);
      setTransactionError('Something went wrong');
    }

  };

  const fetchInitData = async () => {
    setLoading(true);
    try {
      const sofPromise = middleman.get(`/ql/crm/source-of-funds/transaction/${transaction_uuid}`);
      const transactionPromise = middleman.get(`/ql/crm/transactions/get/${transaction_uuid}`);

      const result = await Promise.all([sofPromise, transactionPromise]);
      const [sof, transactionRes] = result;
      const portfolioRes = await middleman.get(`/portfolio/${transactionRes.data.portfolio_uuid}`);
      const foundPolicyHolder = portfolioRes.data.policy_holder[0] || false;
      if (foundPolicyHolder && foundPolicyHolder.type === 'COMPANY') {
        const profileRes = await middleman.get(`/profile/${foundPolicyHolder.uuid}?with=powersofattorney`);
        setSignatories(profileRes.data.signatories);
        setBeneficialOwners(profileRes.data.beneficial_owners);
      }
      const riskRes = await middleman.get(`/risk-assessment/${transactionRes.data.portfolio_uuid}`);
      const riskCategory = riskRes.data;
      setRisk(riskCategory);
      setPolicyHolder(foundPolicyHolder);
      const isCompany = foundPolicyHolder.type === 'COMPANY';
      const purposeFormKey = isCompany ? 'c_da_purpose' : 'p_da_purpose';
      getSummary(purposeFormKey, foundPolicyHolder.uuid, transactionRes.data.portfolio_uuid, setPurposeSummary, undefined, { endDate });
      const sofResult = sof.data;
      const sofResultWithTransactionAmount = sofResult.map(item => {
        item.transactionAmount = item.transactions.reduce((acc, trans) => {
          if (trans.transaction_uuid === transaction_uuid) {
            return acc += trans.amount;
          }
          return acc;
        }
        , 0);
        return item;
      });
      const sourceOfWealthRes = await middleman.get(`/ql/crm/source-of-funds/${foundPolicyHolder.uuid}`);
      setSourceOfWealth(sourceOfWealthRes.data);
      setSources(sofResultWithTransactionAmount);
      setTransaction(transactionRes.data);
      setSelectedSubAccount(transactionRes.data.sub_portfolio_uuid);
      setIssueDate(transactionRes.data.transaction_date);
      if (transactionRes.data.sub_portfolio_uuid && transactionRes.data.transaction_date) {
        setTransactionInfoCompleted(true);
      }
      setPortfolio(portfolioRes.data);
    } catch (e) {
      console.error(e);
    }
    setLoading(false);
  };

  const reset = () => {
    setTransactionError(false);
    setTransactionLoading(false);
    setSources([]);
    setTransaction(false);
    setForm(false);
    setPurposeSummary(false);
    setPortfolio(false);
    setPolicyHolder(false);
    setIssueDate(false);
    setRisk(false);
    setSourceOfWealth(false);
    setSelectedSubAccount(false);
    fetchInitData();
  };

  useEffect(() => {
    fetchInitData();
  }, []);

  useEffect(() => {
    if (transaction) {
      const formResponse = transaction.formResponse;
      if (formResponse) {
        loadForm(
          formResponse.key,
          formResponse.type,
          null,
          transaction.portfolio_uuid,
          setForm);
      }
    }
  }, [transaction]);

  if (loading) return (<LoadingBox />);

  // test

  return (
    <>
      <div className='s-top-xl' />
      <ApprovalProfilePortfolioSummary portfolioUuid={portfolio?.uuid} />
      <ExpandableButton heading={__('risk_tool')} className="s-top-xl">
        <FMATable profileUuid={policyHolder?.uuid} portfolioUuid={portfolio?.uuid} endDate={endDate} withoutSearch />
      </ExpandableButton>
      <ExpandableButton heading={__('risk_profile')}>
        <HubinsRisks portfolioUuid={portfolio?.uuid} endDate={endDate} withoutSearch />
      </ExpandableButton>
      <div className='maxwidth s-top-xl'>
        <CompanyPersonnelPowersOfAttorney
          signatories={signatories}
          beneficialOwners={beneficialOwners}
          profileId={policyHolder?.id}
        />
      </div>
      {(form && transaction?.formResponse) && (
        <FormsSummary
          className="s-top-xl"
          content={form.content}
          response={transaction.formResponse.response}
          title={__("premium_info")}
        />
      )}

      {transaction?.purpose && (
        <div className="s-bottom-xl">
          <Heading size="4" className="s-top-xl s-bottom-lg">
            {__('purpose_of_transaction')}
          </Heading>
          <SimpleTable
            className="s-top-xl"
            headers={[{
              Header: '',
              accessor: 'purpose',
              Cell: ({ value }) => value ? __(value) : ''
            }]}
            data={[transaction]}
          />
        </div>
      )}

      <ClientDocumentsAccordeon
        portfolioUuid={portfolio?.uuid}
        profileUuid={policyHolder?.uuid}
        policyHolderName={policyHolder?.name}
      />
      {purposeSummary ? (
        <FormsSummary
          className="s-top-xl"
          content={purposeSummary.content}
          response={purposeSummary.response}
          title={__("purpose")}
        />
      ) : <LoadingBox />}
      <Heading className="s-bottom-lg" size="4">
        {__('user_sources_for_premium')}
      </Heading>
      <SimpleTable headers={sourceHeaders} data={sources} footer />
      <Heading size="4" className="s-top-xl s-bottom-lg">
        {__('sof')}
      </Heading>
      <SimpleTable data={sourceOfWealth} headers={getDefaultTransactionSourceOfFundsHeaders(risk, sourceOfWealth)} footer />
      {sourceOfWealth && (
        <>
          <Heading size="4" className="s-bottom-lg s-top-xl">
            {__('sow')}
          </Heading>
          <SimpleTable
            className="s-top-xl"
            headers={getTransactionSourceOfWealthHeaders(sourceOfWealth)}
            data={sourceOfWealth}
            title="Source of wealth"
            footer
          />
        </>
      )}
      <Heading size="4" className="s-top-xl s-bottom-lg">
        {__('transaction_info')}
      </Heading>
      <ApprovalTransactionsTable transactions={[transaction]} />
      <Heading size="4" className={'s-top-xl s-bottom-lg'}>
        {__('transactions')}
      </Heading>
      <ReadMore collapsedHeight={100}>
        <TransactionsTable
          data={
            (faTransactionsFetched && faTransactions && faTransactions.transactions)
              ? faTransactions.transactions
              : []}
          isLoading={faTransactionsLoading}
          currency={portfolio.currency}
        />
      </ReadMore>
      <form onSubmit={submit} className={`neutral-2 box-padding s-top-l standard-border ${transactionInfoCompleted ? 'c-success-border' : 'c-neutral-2-border'}`}>
        <ErrorBoundary>
          <ApprovalPaidPremiumTable portfolioUuid={portfolio.uuid} endDate={endDate ? endDate : new Date().toISOString().slice(0, 10)} />
        </ErrorBoundary>
        <Heading size="4" className="s-top-xl s-bottom-lg">
          {__('choose_subaccount')}
        </Heading>
        <ApprovalChooseSubAccount
          portfolioUuid={portfolio.uuid}
          selectedAccount={selectedSubAccount}
          setSelectedAccount={setSelectedSubAccount}
          canEdit={canEdit}
        />
        <DateInput
          className="maxwidth s-top-xl"
          label={__("issue_date")}
          value={issueDate}
          disabled={isExecuted}
          callback={setIssueDate}
        />
        {transaction && sourceOfWealth && sources && canEdit && (
          <ApprovalEditTransactionSources
            transaction={transaction}
            sourceOfFunds={sourceOfWealth}
            usedSources={sources}
            isHighRisk={risk > 2}
            reset={reset}
          />
        )}
        <Button
          type="submit"
          className="cta s-top-l s-left-m"
          loading={transactionLoading}
          disabled={transactionLoading}
        >
          Save
        </Button>
        <ErrorMessage className="s-top-l" text={transactionError} showError={!!transactionError} />
      </form>
    </>
  );
};

export default Deposit;
