import React from 'react';
import ExpandableFormSummary from 'modules/ExpandableFormSummary';
import './index.scss';

type Props = {
  formKey?: string,
  portfolioUuid?: string,
  editLink?: string,
  summary?: any,
  title?: string,
  className?: string,
  titleSize?: number,
  profileUuid?: string,
  endDate?: string,
}

const SettingsExpandableFormSummary = ({ formKey, portfolioUuid, editLink, summary, title, className, titleSize, profileUuid, endDate }: Props) => {
  return (
    <div className={`settings-expandable-form-summary ${className}`}>
      <ExpandableFormSummary
        titleSize={titleSize}
        formKey={formKey}
        portfolioUuid={portfolioUuid}
        profileUuid={profileUuid}
        editLink={editLink}
        summary={summary}
        title={title}
        endDate={endDate}
      />
    </div>
  )
};

export default SettingsExpandableFormSummary;