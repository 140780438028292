import React, { useRef, useState } from 'react';
import Heading from '@hubins/components/Heading';
import ErrorMessage from 'components/ErrorMessage';
import './index.scss'

type DateInputProps = {
  title?: string;
  value?: string;
  callback?: (value: string) => void;
  label?: string;
  error?: string;
  required?: boolean;
  disabled?: boolean;
  className?: string;
  id?: string;
  time?: boolean;
}

const DateInput = ({title, value: propValue, callback, label, error: propError, required, disabled, className, id, time = false}: DateInputProps) => {

  const [value, setValue] = useState(propValue || '');
  const [error, setError] = useState(false);
  const [showError, setShowError] = useState(false);

  const handleChange = (e) => {
    setValue(e.target.value);
    if (callback) {
      callback(e.target.value);
    }
  }

  return (
    <>
      {title && <Heading element="h3" size={4} className="s-bottom-sm">{title}</Heading>}
      <label className={`dateinput__label ${className}`}>
        <div className='dateinput__labeltext label-wrapper'>
          <div className='label'>
            {label && `${label}:`}
          </div>
        </div>
        <input
          onFocus={() => setShowError(false)}
          onBlur={() => setShowError(true)}
          value={value}
          id={id}
          className={`dateinput`}
          type={time ? 'datetime-local' : 'date'}
          onChange={handleChange}
          disabled={disabled}
          pattern="\d{4}-\d{2}-\d{2}"
          required={required}
        />
      </label>
      <ErrorMessage errorMessage={error || propError} showError={(showError && error) ||  propError} />
    </>
  )
};

export default DateInput;