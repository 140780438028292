import React from 'react';
import Icon, { IconProps } from '../IconNew';
import { Link } from "react-router-dom";
import Button from '@hubins/components/Button';
import Heading from '@hubins/components/Heading';
import './index.scss';

interface InnerContentProps {
  icon: string | IconProps,
  customIcon?: React.ReactNode,
  title: string,
  text?: string,
  actionIcon?: string,
  actionText?: string,
  noIconBorder?: boolean
  markAsDone?: boolean,
  iconColor?: string,
}

interface ListButtonProps extends InnerContentProps {
  href?: string,
  onClick: (e: any) => void,
  disabled?: boolean,
  noBorder?: boolean,
  selected?: boolean,
}

// Adding support for both old and new icons here for now
const InnerContent = ({ icon,
  customIcon,
  title,
  text,
  actionIcon,
  actionText,
  noIconBorder,
  markAsDone,
  iconColor
}:InnerContentProps) => {
  return (
    <>
      <span className='list-button__content'>
        {customIcon ? customIcon : (
          <span className={`list-button__icon ${noIconBorder ? 'no-border' : ''}`}>
            <Icon icon={icon} size="24" iconColor={iconColor} />
          </span>
        )}
        <span className='list-button__text'>
          <Heading size="5">{title}</Heading>
          {text && (<>
            <span className='clarification'>{text}</span>
          </>
          )}
        </span>
      </span>
      <Button onlyIcon={(actionText && !markAsDone) ? false : true} className={`${
        markAsDone
        ? 'success'
        : actionText
          ? 'white secondary-hover-animation'
          : 'transparent'}`}
          icon={actionIcon}
          iconColor={markAsDone ? "white" : "primary"} /*backgroundColor={markAsDone ? "success" : "white"}*/
        >
        {actionText && <span>{actionText}</span>}
      </Button>
    </>
  )
}

const ListButton = ({
  href,
  icon,
  customIcon,
  title,
  text,
  onClick = () => {},
  actionIcon = 'arrow right 1',
  actionText = 'Välj',
  disabled = false,
  noBorder = false,
  noIconBorder = false,
  iconColor = 'primary',
  selected = false
}: ListButtonProps) => {

  const markAsDone: boolean = actionIcon == "check signle";
  const innerProps = {
    icon, customIcon, title, text, actionIcon, actionText, noIconBorder, markAsDone, iconColor
  }

  if (href) {
    return (
      <Link
        to={href}
        className={`
          list-button ${disabled ? 'list-button__disabled' : ''}
          ${disabled && !markAsDone ? 'list-button__locked' : ''}
          ${noBorder ? 'no-border' : ''}
          ${selected ? 'list-button__selected' : ''}
          `}
      >
        <InnerContent
          {...innerProps}
        />
      </Link>
      )
  } else {
    return (
    <div
      className={`list-button ${disabled ? 'list-button__disabled' : ''} ${disabled && !markAsDone ? 'list-button__locked' : ''} ${noBorder ? 'no-border' : ''}`}
      onClick={onClick}
    >
        <InnerContent
          {...innerProps}
        />
    </div>
    )
  }
};

export default ListButton;