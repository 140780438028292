import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import Box from 'components/Box';
import Main from 'components/Main';
import Input from '@hubins/components/Input';
import Button, { ButtonWrap } from '@hubins/components/Button';
// import { authOperations } from 'state/ducks/auth';

import __ from 'localisation';
import { Redirect } from 'react-router-dom';
// import { connect } from 'react-redux';
import ErrorMessage from 'components/ErrorMessage';
import Heading from '@hubins/components/Heading';

import ActivateTFAModal from '@hubins/components/ActivateTFAModal';

import middleman /*, { csrf }*/ from 'helpers/middleman';

const LoginFormTFA = ({ authToken, updateUser, redirectTo, reset, tooltip = false }) => {

  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(false);
  const [token, setToken] = useState(false);
  const [activationToken, setActivationToken] = useState(false);
  const [code, setCode] = useState(false);
  const [activationQR, setActivationQR] = useState(false);

  const emailRef = useRef(null);
  const passwordRef = useRef(null);

  const submit = async (e) => {
    try {
      e.preventDefault();
      if (loading) return;
      setLoading(true);

      const body = {
        email: email ? email : emailRef.current.value,
        password: password ? password : passwordRef.current.value,
      };

      const endpoint = '/auth/tfa/login';
      const res = await middleman.promisePost(endpoint, body);
      if (res.data && res.data.authToken) {
        updateUser(res.data);
        return;
      }
      const verificationToken = res.data.token;
      if (verificationToken) {
        setToken(verificationToken);
      } else {
        const activationQRRes = res?.data?.code;
        const resToken = res?.data?.user_token;
        if (!activationQRRes || !resToken) {
          throw new Error('Something went wrong, no token or TFA found');
        }
        setActivationQR(activationQRRes);
        setActivationToken(resToken);
      }

      setError(false);
      setLoading(false);
    } catch (err) {
      console.error(err.message);
      setError(__('email_password_validation_error'));
      setLoading(false);
    }
  };

  const verify = async (e) => {
    try {
      e.preventDefault();
      if (loading) return;
      setLoading(true);
      const body = {
        code, token
      };
      const endpoint = '/auth/tfa/verify';
      const res = await middleman.promisePost(endpoint, body);
      if (res.data && res.data.authToken) {
        updateUser(res.data);
      }
      setLoading(false);
    } catch(err) {
      setError(__('tfa_validation_error'));
      setLoading(false);
    }
  };

  const abort = (e) => {
    e.preventDefault();
    setToken(false);
  };

  if (authToken) {
    return <Redirect to={redirectTo || "/dashboard"} />;
  }
  return (
    <>
      <ActivateTFAModal
        open={activationQR ? true : false}
        setOpen={setActivationQR}
        QRCode={activationQR}
        updateUser={updateUser}
        token={activationToken}
      />
      <Main center>
        <div className='center'>
          <Heading size="1" className="c-primary center s-bottom-xl-womq">
          Backoffice
          </Heading>
          <Box size="large"  bg="primary" style={{ width: 480, maxWidth: "100%" }}>
            <form onSubmit={token ? verify : submit}>
              {token ? (
                <Input
                  type="text"
                  label={__('tfa_code')}
                  value={code}
                  callback={setCode}
                  className="s-md"
                />
              ) : (
                <>
                  <Input
                    type="text"
                    tooltip={tooltip}
                    label={__('username')}
                    ref={emailRef}
                    value={email}
                    callback={setEmail}
                  />
                  <Input
                    type="password"
                    label={__('password')}
                    ref={passwordRef}
                    value={password}
                    callback={setPassword}
                  />
                </>
              )}
              {error && (
                <ErrorMessage
                  showError
                  text={error}
                  className="align-center"
                />
              )}
              <div className="center">
                <ButtonWrap>
                  <Button
                    className="spacing secondary"
                    type="submit"
                    loading={loading}
                    // disabled={loading}
                  >
                    {token ? __('tfa_verify') : __('login')}
                  </Button>
                  {token && (
                    <Button
                      className="spacing secondary center"
                      onClick={abort}
                    >
                      {__('abort')}
                    </Button>
                  )}
                </ButtonWrap>
              
                {(reset && !token) && (
                  <Link to="/reset" className="link link--white center s-md">{__('forgot_password')}</Link>
                )}
              </div>
            </form>
          </Box>
        </div>
      </Main>
    </>
  );
};

export default LoginFormTFA;

// function mapStateToProps(state) {
//   return {
//     authToken: state.auth.User.authToken,
//   };
// }

// export default connect(
//   mapStateToProps,
//   {
//     updateUser: authOperations.updateUser,
//   }
// )(LoginFormTFA);
