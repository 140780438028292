import React from 'react';
import { useTaxDomiciles } from 'queries/taxDomiciles';
import { __ } from 'localisation';
import SimpleTable from 'modules/Tables/SimpleTable';
import LoadingBar from '@hubins/components/LoadingIndicators';

const headers = [
  {
    Header: __('country'),
    accessor: 'country',
  },
  {
    Header: __('tin_number'),
    accessor: 'tin',
  },
];

const loadingObj = {
  country: <LoadingBar />,
  tin: <LoadingBar />,
};

const loadingState = [
  loadingObj,
]

const TaxDomiciles = ({ profileUuid }) => {
  const { data, isLoading, isFetched } = useTaxDomiciles(profileUuid);

  return (
    <SimpleTable
      headers={headers}
      data={isLoading || !isFetched ? loadingState : (data?.data || [])}
    />
  );
};

export default TaxDomiciles;