import React, { useState, useCallback, useEffect } from 'react';
import AllPortfolioAndProfileApprovalsTable from '@hubins/components/AllPortfolioAndProfileApprovalsTable';
import Heading from '@hubins/components/Heading';
import DateInput from '@hubins/components/DateInput';
import Button from '@hubins/components/Button';
import __ from 'localisation';
import SourceOfFundsList from '@hubins/components/SourceOfFundsList';
import FormsSummary from 'modules/FormsSummary';
import BusinessProfilePortfolioTable from '@hubins/components/BusinessProfilePortfolioTable';
import middleman from 'helpers/middleman';
import { getSelectedDatePlusOneDay } from 'helpers/date';
import Comments from '@hubins/components/Comments';

const BusinessProfile = ({ profile, portfolioUuid, profileUuid, portfolio }) => {

  const [date, setDate] = useState(new Date().toISOString().split('T')[0]);
  const [endDate, setEndDate] = useState(getSelectedDatePlusOneDay());
  const [risk, setRisk] = useState(null);
  const [riskLoading, setRiskLoading] = useState(false);
  const [importedFmaCategory, setImportedFmaCategory] = useState(0);

  const isCompany = profile.type === 'COMPANY';

  const search = useCallback(() => {
    setEndDate(getSelectedDatePlusOneDay(date));
  }, [date, setEndDate]);

  const getRiskGroup = (score) => {
    if (score < 0) {
      return 1;
    } else if (score < 3) {
      return 2;
    } else if (score < 25) {
      return 3;
    } else if (score >= 25) {
      return 4;
    } else {
      return 2;
    }
  };
  const fetchData = async () => {
    try {
      setRiskLoading(true);
      const [response, response2] = await Promise.all([
        middleman.get(`/ql/crm/risk-assessment/fma/${profileUuid}/${portfolioUuid}/${endDate}`),
        middleman.get(`/ql/crm/risk-assessment/fma/manual-risk/${portfolioUuid}`)
      ]);
      setImportedFmaCategory(response2?.data || 0);
      const score = response.data.reduce((acc, curr) => acc + curr.score, 0);
      setRisk(getRiskGroup(score) || 0);
      setRiskLoading(false);
    } catch (err) {
      console.error(err);
    }
  };



  useEffect(() => {
    fetchData();
  }, [endDate]);

  return (
    <>
      <div className='maxwidth'>
        <DateInput
          label={__('date')}
          value={date}
          callback={setDate}
          className='input-max-width'
        />
        <Button
          onClick={search}
          icon="Button Refresh Arrows by Streamlinehq"
          className='s-bottom-xl s-top-md'
        >
          {__('search')}
        </Button>
      </div>
      <BusinessProfilePortfolioTable
        portfolio={portfolio}
        loading={riskLoading}
        risk={risk}
        importedFmaCategory={importedFmaCategory}
      />
      <FormsSummary
        forms={[isCompany ? 'c_bi' : 'p_roaring']}
        type="register"
        userId={!isCompany ? profile.national_identity_number : undefined}
        endDate={endDate}
        profileUuid={isCompany ? profileUuid : undefined}
        language="en"
      />
      <FormsSummary
        forms={[isCompany ? 'c_kyc' : 'p_kyc']}
        type="register"
        profileUuid={profileUuid}
        endDate={endDate}
        language="en"
      />
      <SourceOfFundsList
        profileUuid={profileUuid}
        endDate={endDate}
        risk={risk}
      />
      <div className="s-top-xl" />
      <FormsSummary
        forms={[isCompany ? 'c_da_purpose' : 'p_da_purpose']}
        type="register"
        profileUuid={profileUuid}
        portfolioUuid={portfolioUuid}
        endDate={endDate}
        language="en"
      />
      <Comments
        title={__('comments')}
        model='BusinessProfile'
        modelUuid={portfolioUuid}
        presetChannels={['hubins_backoffice', 'hubins_compliance', 'quantum_backoffice', 'quantum_compliance']}
      />
      <Heading size="4" className="s-top-xl s-bottom-m">{__('latest_approvals')}</Heading>
      <AllPortfolioAndProfileApprovalsTable
        profileUuid={profileUuid}
        portfolioUuid={portfolioUuid}
      />
    </>
  );

};

export default BusinessProfile;
